// color design tokens export
export const tokensDark = {
    grey: {
        0: "#ffffff", // manually adjusted
        10: "#f6f6f6", // manually adjusted
        50: "#f0f0f0", // manually adjusted
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
        1000: "#000000", // manually adjusted
    },
    primary: {
        // white
        100: "#ffffff", // Pure white
        200: "#f7f7f7", // Very light grey, almost white
        300: "#f0f0f0", // Light grey, leaning towards white
        400: "#e8e8e8", // Light grey
        500: "#e0e0e0", // Slightly darker light grey
        600: "#d8d8d8", // Another shade of light grey
        700: "#d0d0d0", // Light grey
        800: "#c8c8c8", // Light grey
        900: "#c0c0c0", // Light grey
    },
    secondary: {
        // yellow
        50: "#f0f0f0", // manually adjusted
        100: "#fff6e0",
        200: "#ffedc2",
        300: "#ffe3a3",
        400: "#ffda85",
        500: "#ffd166",
        600: "#cca752",
        700: "#997d3d",
        800: "#665429",
        900: "#332a14",
    },
    alert: {
        50: "#fdf2f2",
        100: "#f9d4d4",
        200: "#f4b6b6",
        300: "#ef9797",
        400: "#eb7979",
        500: "#e65b5b", // Main red color for alerts
        600: "#c64747",
        700: "#a23333",
        800: "#7e1f1f",
        900: "#5a0b0b",
    },
};

// function that reverses the color palette
function reverseTokens(tokensDark) {
    const reversedTokens = {};
    Object.entries(tokensDark).forEach(([key, val]) => {
        const keys = Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj = {};
        for (let i = 0; i < length; i++) {
            reversedObj[keys[i]] = values[length - i - 1];
        }
        reversedTokens[key] = reversedObj;
    });
    return reversedTokens;
}
export const tokensLight = reverseTokens(tokensDark);

// mui theme settings
export const themeSettings = (mode) => {
    console.log(mode)
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    // Palette values for dark mode
                    primary: {
                        main: tokensDark.primary[400], // Adjusted main color for primary
                        light: tokensDark.primary[400],
                    },
                    secondary: {
                        main: tokensDark.secondary[300], // Adjusted main color for secondary
                    },
                    neutral: {
                        main: tokensDark.grey[500], // Adjusted main color for neutral
                    },
                    background: {
                        default: tokensDark.primary[600], // Adjusted default background color
                        alt: tokensDark.primary[500], // Adjusted alternate background color
                    },
                    alert: {
                        // Define alert palette for dark mode (similar to secondary)
                        // default: "#fdf2f2", // Adjusted default background color
                        main: tokensDark.alert[400]
                    },
                }
                : {
                    
                    // Palette values for light mode
                    primary: {
                        main: tokensDark.grey[50], // Adjusted main color for primary
                        light: tokensDark.grey[100],
                    },
                    secondary: {
                        main: tokensDark.secondary[600], // Adjusted main color for secondary
                        light: tokensDark.secondary[700],
                    },
                    neutral: {
                        main: tokensDark.grey[500], // Adjusted main color for neutral
                    },
                    background: {
                        default: tokensDark.grey[0], // Adjusted default background color
                        alt: tokensDark.grey[50], // Adjusted alternate background color
                    },
                    alert: {
                        // Define alert palette for light mode (similar to secondary)
                       default:tokensDark.alert[50],
                       alt:tokensDark.alert[200]
                    },
                }),
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
    };
};