import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Button,
    Typography,
    Rating,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { useGetProductsQuery } from "state/api";
import Axios from "axios";

const Product = ({
                     _id,
                     name,
                     description,
                     category,
                     origin,
                    quantity,
                     unit,
                     dateOfProduction,
                     size,
                 }) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
            }}
        >
            <CardContent>
                <Typography
                    sx={{ fontSize: 18,fontWeight:'bold' }}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                >
                    {category}
                </Typography>
                <Typography  sx={{ fontSize: 14,fontWeight:'600' }} variant="h5" component="div">
                    {name}
                </Typography>

                <Typography  sx={{ fontSize: 12,fontWeight:'400' }} variant="body2">{description}</Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See More
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300],
                }}
            >
                <CardContent>
                    <Typography>Units: {unit}</Typography>
                    <Typography>Date of Production: {dateOfProduction}</Typography>
                    <Typography>Size: {size}</Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
};

const Products = () => {
    const { data, isLoading } = useGetProductsQuery();
    const [purchase,setPurchasePlaceOrderForm] = useState([])
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState(null);
    console.log('Data - ',isLoading)
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await Axios.get(`https://server.asvpharmatech.com/api/warehouse/product/get`);
    //             console.log(response.data)
    //             if (response.data) {
    //                 setPurchasePlaceOrderForm(response.data);
    //             } else {
    //                 console.error('No data received');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //             setError(error.message);
    //         }
    //     };

    //     fetchData();
    // }, []);

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="PRODUCTS" subtitle="See your list of products." />
            {data || !isLoading ? (
                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    justifyContent="space-between"
                    rowGap="20px"
                    columnGap="1.33%"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                >
                    {
                    
                    data?.data?.map(
                        ({
                             _id,
                             name,
                             description,
                             category,
                             origin,
                             quantity,
                             unit,
                             size,
                             dateOfProduction,
                         }) => (
                            <Product
                                key={_id}
                                _id={_id}
                                name={name}
                                description={description}
                                category={category}
                                origin={origin}
                                quantity={quantity}
                                unit={unit}
                                size={size}
                                dateOfProduction={dateOfProduction}
                            />
                        )
                    )
                    }

                </Box>
            ) : (
                <>Loading...</>
            )}
        </Box>
    );
};

export default Products;
