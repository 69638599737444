import React from "react";
import * as Components from './Components';
import {Card, CardContent, useTheme, Alert,
    AlertTitle} from "@mui/material";
import {Parent} from "./Components";
import axios from "axios";
import axiosClient from "../../state/axios-client";
import { useStateContext } from "../../state/ContextProvider";
import {Modal} from "reactstrap";

import { Navigate } from "react-router-dom";

function Login() {
    const [signIn, toggle] = React.useState(true);
    const theme = useTheme();
    const {setUser,setToken,token} = useStateContext()

    const [user,SetUser] = React.useState({
      name:null,
      email:null,
      phone_no:null,
      password:null,
      password_confirmation:null
    })

    const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
    })

    function onCloseAlert() {
        setAlert({
            type: '',
            text: '',
            show: false
        })
    }

    function onShowAlert(type,text) {
        console.log(type)
        setAlert({
            type: type,
            text: text,
            show: true
        })
    }

    const onSignup= (ev) => {
      ev.preventDefault();
      axiosClient.post("/signup",user)
      .then(({data}) => {
        setToken(data.token)
        setUser(data.user)
        //console.log("woww u successfully logged in")
        //console.log("token is")
        //console.log(token)
      })
      .catch(err=>{
        const response = err.response;
        if(response && response.status=== 422) {
          //console.log(response.data.errors);
          //setErrors(response.data.errors);
        }
      })
    }


	const onLogin = (ev) => {
		ev.preventDefault();
		// setErrors(null);
		axiosClient.post("/login",user)
		.then(({data}) => {
		    console.log(data)
		    if(data.message){
                console.log(data)
                onShowAlert('error', data.message)
            }
		    else{
                setToken(data.token)
                SetUser(data.user)
                return <Navigate to= "/login"/>
            }
		})
		.catch(err=>{
			const response = err.response;
			if(response && response.status=== 422) {
				//console.log(response.data.errors);
				if(response.data.errors){
                    onShowAlert('error', response.message)
					//setErrors(response.data.errors);
                    //console.log(response.data.message)
				} else {
                    onShowAlert('error', response.message)
					 /*setErrors({
					 	email:[response.data.message]
					 });*/
          //console.log(response.data.message)
				}
			}
		})
	}

    return(
        <Parent >
            <Components.Container >
                <Components.SignUpContainer signinIn={signIn}>
                    <Components.Form>
                        <Components.Title>Create Account</Components.Title>
                        <Components.Input type='text' value={user.name} onChange={ev=>SetUser({...user,name:ev.target.value})} placeholder='Name' />
                        <Components.Input type='email' value={user.email} onChange={ev=>SetUser({...user,email:ev.target.value})} placeholder='Email' />
                        <Components.Input type='text' value={user.phone_no} onChange={ev=>SetUser({...user,phone_no:ev.target.value})} placeholder='Phone Number' />
                        <Components.Input type='password' value={user.password} onChange={ev=>SetUser({...user,password:ev.target.value})} placeholder='Password' />
                        <Components.Input type='password' value={user.password_confirmation} onChange={ev=>SetUser({...user,password_confirmation:ev.target.value})} placeholder='Password Confirmation' />
                        <Components.Button onClick={onSignup}>Sign Up</Components.Button>
                    </Components.Form>
                </Components.SignUpContainer>

                <Components.SignInContainer signinIn={signIn}>
                    <Components.Form>
                        <Components.Title>Sign in</Components.Title>
                        <Components.Input type='email' value={user.email} onChange={ev=>SetUser({...user,email:ev.target.value})} placeholder='Email' />
                        <Components.Input type='password' value={user.password} onChange={ev=>SetUser({...user,password:ev.target.value})} placeholder='Password' />
                        <Components.Anchor href='#'>Forgot your password?</Components.Anchor>
                        <Components.Button onClick={onLogin}>Sign In</Components.Button>
                    </Components.Form>
                </Components.SignInContainer>

                <Components.OverlayContainer signinIn={signIn}>
                    <Components.Overlay signinIn={signIn}>

                        <Components.LeftOverlayPanel signinIn={signIn}>
                            <Components.Title>Welcome Back!</Components.Title>
                            <Components.Paragraph>
                                To keep connected with us please login with your personal info
                            </Components.Paragraph>
                            <Components.GhostButton onClick={() => toggle(true)}>
                                Sign In
                            </Components.GhostButton>
                        </Components.LeftOverlayPanel>

                        <Components.RightOverlayPanel signinIn={signIn}>
                            <Components.Title>Create Your new Account</Components.Title>
                            <Components.Paragraph>
                                Enter Your personal details and start your work with us
                            </Components.Paragraph>
                            <Components.GhostButton onClick={() => toggle(false)}>
                                Sign Up
                            </Components.GhostButton>
                        </Components.RightOverlayPanel>

                    </Components.Overlay>
                </Components.OverlayContainer>

                <Modal
                    size='lg'
                    isOpen={alert.show}
                >
                    <Alert
                        severity={alert.type}
                        btnText={'Close'}
                        onClose={onCloseAlert}
                        pressCloseOnOutsideClick={true}
                        showBorderBottom={true}
                    >
                        <AlertTitle>{alert.title}</AlertTitle>
                        {alert.text}
                    </Alert>
                </Modal>

            </Components.Container>
        </Parent>
    )
}

export default Login;
