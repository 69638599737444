import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent, Checkbox, FormControl, FormControlLabel, FormLabel,
    IconButton,
    InputBase, Radio, RadioGroup,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import {Add, ClearRounded, CategoryRounded , Create, Search} from "@mui/icons-material";
import FlexBetween from "../../components/FlexBetween";
import {FormGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import axiosClient from "../../state/axios-client";
import {useGetTasksListQuery} from "state/api";
import Requestbox from "../../components/Requestbox";

const DepartmentTaskbox = ({name, pending, complete}) =>{
    const theme = useTheme();

    return(
        <Card
            sx={{
                marginX:2,
                marginY:2,
                backgroundImage: "none",
                backgroundColor: theme.palette.primary[600],
                borderRadius: "0.55rem",
                flexWrap:'wrap'
            }}>
            <CardContent sx={{display:'flex',justifyContent:'center',flexDirection:"column"}}>
                <Typography color={theme.palette.secondary[500]} variant="h4" fontWeight={'bold'} alignSelf={'center'}>
                    {name}
                </Typography>
                <CardContent>
                    <Box sx={{display:'flex', flexDirection:'colum',justifyContent:'center',gap:1}}>
                        <Box  sx={{display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <Typography variant="h1">
                                {pending}
                            </Typography>
                            <Typography sx={{display:"flex",justifyContent:'center',alignItems:'center'}}>
                                In progress
                            </Typography>
                        </Box>
                        {/*<Box sx={{backgroundColor:theme.palette.secondary[400] , width:1, margin: 1, flexWrap:'wrap'}}/>*/}
                        <Box sx={{display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}} marginX={1}>
                            <Typography variant="h1">
                                {complete}
                            </Typography>
                            <Typography>
                                Completed
                            </Typography>
                        </Box>
                    </Box>

                </CardContent>

            </CardContent>

        </Card>
    )
}

const TaskList = () => {
    const theme = useTheme();

    const [modal, setModal] = useState(false)

    const [taskType, setTaskType] = useState('general');
    const isProductionOrDispatch = taskType === 'production' || taskType === 'dispatch';

    const [departments, setDepartments] = useState()
    const [categoryOptions, setCategoryOptions] = useState([])
    const [showCategoryCreate, setShowCategoryCreate] = useState(false);
    const [dispatchedProductsOptions, setDispatchedProductsOptions] = useState([])
    const unitOptions = ['Nos', 'mm','m'];

    const [taskForm,setTaskForm] = React.useState({
        taskType:taskType,
        taskName:'',
        TaskDetails:'',
        department:'',
        productName:'',
        productDispatched:'',
        productSize:'',
        taskCategory:'',
        taskNewCategory:'',
        productQuantity:'',
        productUnits:'',
    })

    const [departmentList, setDepartmentList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [taskList, setTaskList] = useState([])
    useEffect(() => {
        getTaskList();
    }, [])
    const getTaskList = () => {
        axiosClient.get("management/task/list")
            .then(({data}) => {
                setIsLoading(false);
                setDepartmentList(data.data.departments)
                setTaskList(data.data.tasks)
            })
            .catch(err=>{
                const response = err.response;
                if(response && response.status=== 422) {
                    //console.log(response.data.errors);
                    if(response.data.errors){
                        // setErrors(response.data.errors);
                        //console.log(response.data.message)
                    } else {
                        // setErrors({
                        // 	email:[response.data.message]
                        // });
                        //console.log(response.data.message)
                    }
                }
            })
    }

    const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
    })

    function onCloseAlert() {
        setAlert({
            type: '',
            text: '',
            show: false
        })
    }

    function onShowAlert(type,text) {
        setAlert({
            type: type,
            text: text,
            show: true
        })
    }

    const getDepartments = () => {
        axiosClient.get('department/get')
            .then(({ data }) => {
                setDepartments(data.data);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCategories = () => {
        axiosClient.get('product/category/get')
            .then(({ data }) => {
                setCategoryOptions(data.data);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getDispatchedProducts = () => {

        try {
            axiosClient.get('warehouse/get/raw-material')
                .then((response)=>{
                    setDispatchedProductsOptions(response.data.data);
                });
        } catch (error) {
            console.log(error)
        }
        /*
        axiosClient.get('warehouse/product/get/list')
            .then(({ response }) => {
                setDispatchedProductsOptions(response.data.data);
            })
            .catch((error) => {
                console.log(error)
            })*/
    }

    async function loadTaskModal (ev) {
        ev.preventDefault();

        setModal(true)

        getDepartments()
        getCategories()
        getDispatchedProducts()
    }

    async function addTaskFormCreate (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('task/add', taskForm)
                // axios.post('client/products/add', productForm)
                .then((response)=>{
                    setModal(false);
                    getTaskList();
                    setTaskForm([]);
                    setTaskType("general");
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            console.log(error)
        }

    }


    /*
    const [task, setTask] = useState([])

    useEffect(() => {
        //loadTaskList();
    }, [])

    async function loadTaskList () {

        if(purchaseProducts.length === 0) {
            try {
                axiosClient.get('purchase/entry/get')
                    .then((response)=>{
                        if(response.data.data) {
                            setPurchaseProductSteps(response.data.data.steps);
                            setPurchaseProducts(response.data.data.products);
                            setVendorTokenNo(response.data.data.vendorToken);
                        }
                    });
            } catch (error) {
                onShowAlert('error',error.message)
            }
        }
    }
     */

    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Task Name",
            flex: 1,
        },
        {
            field: "department",
            headerName: "Department",
            flex: 0.5,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.5,
        },
    ];


    return (
        <Box m="1.5rem 2.5rem">
            <Modal
                size='lg'
                isOpen={modal}
                toggle={()=>setModal(!modal)}

            >

                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Add Task
                        </Typography>
                    </ModalHeader>

                    <ModalBody>

                        <CardContent>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">
                                   <span style={{color:"black"}}>
                                     Task Type
                                    </span>
                                    </FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="General Task"
                                    color="black"
                                    name="taskType" value={taskForm.taskType} onChange={ev=>setTaskForm({...taskForm,taskType:ev.target.value})}
                                >
                                    <FormControlLabel value="general" color="black" control={<Radio sx={{ color: 'black' }} />} checked={taskType === "general"} onChange={e => setTaskType(e.target.value)} label="General Task" />
                                    <FormControlLabel value="production" control={<Radio sx={{ color: 'black' }} />} checked={taskType === "production"} onChange={e => setTaskType(e.target.value)} label="Production Task" />
                                    <FormControlLabel value="dispatch" control={<Radio sx={{ color: 'black' }} />} checked={taskType === "dispatch"} onChange={e => setTaskType(e.target.value)} label="Dispatch Task" />
                                </RadioGroup>
                            </FormControl>
                        </CardContent>

                        <CardContent>
                            <TextField id="outlined-basic" name="taskName" onChange={ev=>setTaskForm({...taskForm,taskName:ev.target.value})} label="Task Name" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="TaskDetails" onChange={ev=>setTaskForm({...taskForm,TaskDetails:ev.target.value})} label="Task Details" variant="outlined" sx={{flex:1,display:'flex'}}
                                       multiline={true}/>
                        </CardContent>
                        <CardContent>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={departments}
                                sx={{flex:1,display:'flex'}}
                                name="department"
                                onChange={(event, value) => setTaskForm({
                                    ...taskForm,
                                    department: value
                                })}
                                renderInput={(params) => <TextField {...params} label="Assign to"
                                />}
                            />
                        </CardContent>
                        {taskType === 'dispatch'&&
                            <CardContent>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={dispatchedProductsOptions}
                                    sx={{flex:1,display:'flex'}}
                                    name="productDispatched"
                                    onChange={(event, value) => setTaskForm({
                                        ...taskForm,
                                        productDispatched: value
                                    })}
                                    renderInput={(params) => <TextField {...params}
                                        label="Product to be Dispatched"
                                    />}
                                />
                            </CardContent>}
                        {taskType === 'production'&&
                            <CardContent>
                                <TextField id="outlined-basic" name="productName" onChange={ev=>setTaskForm({...taskForm,productName:ev.target.value})} label="Product Name" variant="outlined" sx={{flex:1,display:'flex'}} />
                            </CardContent>
                        }
                        {isProductionOrDispatch &&
                            <CardContent>
                                <TextField id="outlined-basic" name="productSize" onChange={ev=>setTaskForm({...taskForm,productSize:ev.target.value})} label="Size" variant="outlined" sx={{flex:1,display:'flex'}} />
                            </CardContent>
                        }
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={categoryOptions}
                                sx={{flex:1,display:'flex'}}
                                name="taskCategory"
                                value={taskForm.productCategory}
                                onChange={(event, value) => setTaskForm({
                                    ...taskForm,
                                    taskCategory: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                    label="Category"
                                />}
                            />
                            <Button variant="contained" startIcon={<CategoryRounded />}
                                     sx={{
                                        ':hover': {
                                     bgcolor: 'white',
                                     color: 'black',
                                        },
                                       backgroundColor: "black",
                                       color: "white",
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       padding: "10px 20px",
                                   }}
                                    onClick={() => setShowCategoryCreate(true)} >
                                Create New Category
                            </Button>
                        </CardContent>
                        {showCategoryCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="taskNewCategory" onChange={ev=>setTaskForm({...taskForm,taskNewCategory:ev.target.value})} label="Category Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                        sx={{backgroundColor:theme.palette.secondary.light,
                                            color:theme.palette.primary.dark}}
                                        onClick={() => setShowCategoryCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>
                        )}
                        {isProductionOrDispatch &&
                            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="productQuantity" onChange={ev=>setTaskForm({...taskForm,productQuantity:ev.target.value})} label="Quantity" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={unitOptions}
                                    sx={{flex:1,display:'flex'}}
                                    name="productUnits"
                                    onChange={(event, value) => setTaskForm({
                                        ...taskForm,
                                        productUnits: value
                                    })}
                                    renderInput={(params) => <TextField {...params} label="Units"
                                    />}
                                />
                            </CardContent>
                        }

                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" startIcon={<Create />} color="secondary" onClick={(ev)=> addTaskFormCreate(ev)} >
                                Create
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setModal(false)}>
                                Cancel
                            </Button>
                        </CardContent>
                    </ModalBody>

                </Card>

            </Modal>

            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                <Header title="Task List" />
                <FlexBetween
                    backgroundColor ={theme.palette.background.alt}
                    borderRadius = "9px"
                    gap="3rem"
                    p="0.1rem 1.5rem"
                    sx={{marginX:5,flexGrow:1}}
                >
                    <InputBase placeholder="Search..."/>
                    <IconButton>
                        <Search/>
                    </IconButton>
                </FlexBetween>
                <Button variant="contained" startIcon={<Add />} onClick={(ev)=>loadTaskModal(ev)}>
                    New Task
                </Button>
            </CardContent>


            <Box>
                <Card
                    sx={{
                        marginTop:5,
                        marginBottom:5,
                        backgroundImage: "none",
                        backgroundColor: theme.palette.background.alt,
                        borderRadius: "0.55rem",
                    }}
                >
                    <CardContent>
                        <Typography variant="h4">Departments Record</Typography>
                    </CardContent>
                    <Box sx={{display: 'flex', flexDirection: 'colum'}}>
                        {departmentList && departmentList.map(department => (
                            <DepartmentTaskbox
                                key={department.id}
                                name={department.name}
                                pending={department.pending}
                                complete={department.complete}
                            />
                        ))}
                    </Box>

                </Card>
            </Box>

            <Box
                mt="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !taskList}
                    getRowId={(row) => row._id}
                    rows={taskList || []}
                    columns={columns}
                    components={{
                        ColumnMenu: CustomColumnMenu,
                    }}
                />
            </Box>

        </Box>
    );
};

export default TaskList;
