import React, {useState, useEffect} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent, FormControl, FormControlLabel, FormLabel,
    IconButton,
    InputBase, Radio, RadioGroup,
    TextField,
    Typography,
    useTheme,
    Alert,
    AlertTitle,
    Stack
} from "@mui/material";
import {useGetAdminsQuery, useGetProductsQuery} from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import {
    Add,
    CategoryRounded,
    ClearRounded,
    Create,
    CreateRounded,
    LocalShippingRounded, PeopleAlt, Save,
    Search,
    Send
} from "@mui/icons-material";
import FlexBetween from "../../components/FlexBetween";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import axiosClient from "../../state/axios-client";
import axios from "axios";

const Warehouse = () => {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [sort, setSort] = useState({});

    const { data, isLoading } = useGetProductsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search,
    });
    /*
    const { data, isLoading } = useGetAdminsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search,
    });*/
    const [selectedOrigin, setSelectedOrigin] = useState("Produced");

    const submitProduct= ()=>{
    }

    const [productForm,setProductForm] = React.useState({
      productName:'',
      productCategory:'',
      productNewCategory:'',
      productQuantity:'',
      productUnits:'',
      productSize:'',
      productDetails:'',
      productOrigin:''
    })

    const columns = [
        {
            field: "name",
            headerName: "Product Name",
            flex: 0.5,
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
        },
        {
            field: "category",
            headerName: "Category",
            flex: 0.5,

        },
        {
            field: "origin",
            headerName: "Origin",
            flex: 0.4,
        },
        {
            field: "quantity",
            headerName: "Quantity",
            flex: 0.5,
        },
        {
            field: "size",
            headerName: "Size",
            flex: 0.5,
        },
        {
            field: "role",
            headerName: "edit",
            flex: 0.5,
        },
    ];

    const [modal, setmodal] = useState(false)
    const [modaledit, setmodaledit] = useState(false)
    const [modaldis, setmodalDis] = useState(false)
    const [optionsCat, setOptionsCat] = useState([])
    //const optionsCat = ['Pharama tech', 'simulations','equipment'];
    //const optionsCat = [];
    const optionsQuant = ['Nos', 'mm','m'];
    //const optionsDisProduct = ['Gasket', 'Silicon','Wheels'];

    const [showRawProductCreate, setShowRawProductCreate] = useState(false);
    const [optionsDisProduct, setOptionsDisProduct] = useState([]);
    const [products, setProducts] = useState(false);

    useEffect(() => {
        try {
            axiosClient.get('product/category/get')
                .then((response)=>{
                    setOptionsCat(response.data.data);
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }
    }, []);

    const [optionsRawMaterial, setOptionsRawMaterial] = useState([])
    useEffect(() => {
        try {
            axiosClient.get('warehouse/get/raw-material')
                .then((response)=>{
                    setOptionsDisProduct(response.data.data);
                    setOptionsRawMaterial(response.data.data);
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }
    }, []);

    useEffect(() => {
        try {
            axiosClient.get('warehouse/product/get')
                .then((response)=>{
                    setProducts(response.data.data);
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }
    }, []);


  const [alert, setAlert] = React.useState({
      type: 'error',
      text: 'This is a alert message',
      show: false
    })

    function onCloseAlert() {
      setAlert({
        type: '',
        text: '',
        show: false
      })
    }

    function onShowAlert(type,text) {
      setAlert({
        type: type,
        text: text,
        show: true
      })
    }

    async function addProductFormCreate (ev) {
     ev.preventDefault();

        if(productForm.productOrigin === "RawMaterial") {
            if (!productForm.productName && !productForm.productNewName) {
                onShowAlert('error', 'Product name is required.');
                return false
            }
        }
        else{
            if (!productForm.productName) {
                onShowAlert('error', 'Product name is required.');
                return false
            }
        }

      if(productForm.productOrigin !== "RawMaterial") {
          if (!productForm.productNewCategory && !productForm.productCategory) {
              onShowAlert('error', 'Invalid product category.');
              return false
          }

          if (!productForm.productUnits) {
              onShowAlert('error', 'Invalid product units.');
              return false
          }

          if (!productForm.productSize) {
              onShowAlert('error', 'Product size is required.');
              return false
          }
      }

      if (isNaN(productForm.productQuantity)) {
          onShowAlert('error', 'Invalid product quantity.');
          return false
      }

      if (productForm.productOrigin !== "Produced" && productForm.productOrigin !== "Purchased" && productForm.productOrigin !== "RawMaterial") {
          onShowAlert('error', 'Invalid product origin.');
          return false
      }
        try {
          axiosClient.post('warehouse/product/add', productForm)
          // axios.post('client/products/add', productForm)
          .then((response)=>{
              setProductForm([]);
              setSelectedOrigin("Produced");
            onShowAlert('success',response.data.message)
          });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    const [dispatchProductForm, setDispatchProductForm] = React.useState({
        dispatchProduct:'',
        dispatchQuantity:'',
        dispatchUnit:'',
        dispatchTo:''
    })

    // async function addDispatchProductFormCreate () {
    //     //ev.preventDefault();

    //     try {
    //         axiosClient.post('warehouse/product/dispatch', dispatchProductForm)
    //             // axios.post('client/products/add', productForm)
    //             .then((response)=>{
    //                 setProductForm([]);
    //                 setSelectedOrigin("Produced");
    //                 onShowAlert('success',response.data.message)
    //             });
    //     } catch (error) {
    //         onShowAlert('error',error.message)
    //     }

    // }
    async function addDispatchProductFormCreate() {
        // ev.preventDefault(); // Make sure to pass event if you're planning to use this
    
        try {
            const response = await axiosClient.post('warehouse/product/dispatch', dispatchProductForm);
            setProductForm([]);
            setSelectedOrigin("Produced");
            onShowAlert('success', response.data.message);
        } catch (error) {
            onShowAlert('error', error.response?.data?.message || error.message);
        }
    }

    const [showCatCreate, setShowCatCreate] = useState(false);

    return (
        <Box m="1.5rem 2.5rem">
            <Modal
                size='lg'
                isOpen={modal}
                toggle={()=>setmodal(!modal)}

            >
                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Add Product
                        </Typography>
                    </ModalHeader>

                    <ModalBody>

                        <CardContent>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Product Origin</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Produced"
                                    name="productOrigin" value={productForm.productOrigin} onChange={ev=>setProductForm({...productForm,productOrigin:ev.target.value})}
                                >
                                    <FormControlLabel value="Produced" control={<Radio />} checked={selectedOrigin === "Produced"} onChange={e => setSelectedOrigin(e.target.value)} label="Produced" />
                                    <FormControlLabel value="Purchased" control={<Radio />} onChange={e => setSelectedOrigin(e.target.value)} label="Purchased" />
                                    <FormControlLabel value="RawMaterial" control={<Radio />} onChange={e => setSelectedOrigin(e.target.value)} label="Raw Material" />

                                </RadioGroup>
                            </FormControl>
                        </CardContent>

                        {selectedOrigin === "RawMaterial" &&
                        <CardContent sx={{display: 'flex', flexDirection: 'colum', justifyContent: 'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsRawMaterial}
                                sx={{flex: 1, display: 'flex'}}
                                name="productName"
                                onChange={(event, value) => setProductForm({
                                    ...productForm,
                                    productName: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                                                    label="Product Name"
                                />}
                            />
                            <Button variant="contained" startIcon={<PeopleAlt/>}
                                    sx={{
                                        backgroundColor: theme.palette.secondary.light,
                                        color: theme.palette.primary.dark
                                    }}
                                    onClick={() => setShowRawProductCreate(true)}>
                                Create New Product
                            </Button>
                        </CardContent>

                        }

                        { selectedOrigin === "RawMaterial" && showRawProductCreate &&
                            <CardContent
                            sx={{display: 'flex', flexDirection: 'colum', justifyContent: 'space-between'}}>
                            <TextField id="outlined-basic" name="productNewName" onChange={ev=>setProductForm({...productForm,productNewName:ev.target.value})} label="Product Name" variant="outlined" sx={{
                            flex: 1,
                            display: 'flex'
                        }} />
                            </CardContent>}


                        {selectedOrigin !== "RawMaterial" && (

                        <CardContent sx={{display: 'flex', flexDirection: 'colum', justifyContent: 'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo1"
                                options={optionsCat}
                                sx={{flex: 1, display: 'flex'}}
                                name="productCategory"
                                onChange={(event, value) => setProductForm({
                                    ...productForm,
                                    productCategory: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                    label="Category"
                                />}
                            />
                            <Button variant="contained" startIcon={<CategoryRounded/>}
                                    sx={{
                                        ':hover': {
                                     bgcolor: 'white',
                                     color: 'black',
                                        },
                                       backgroundColor: "black",
                                       color: "white",
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       padding: "10px 20px",
                                   }}
                                    onClick={() => setShowCatCreate(true)}>
                                Create New Category
                            </Button>
                        </CardContent>

                            )}

                        {selectedOrigin !== "RawMaterial" && showCatCreate && (
                            <CardContent
                            sx={{display: 'flex', flexDirection: 'colum', justifyContent: 'space-between'}}>
                            <TextField id="outlined-basic" name="productNewCategory" onChange={ev=>setProductForm({...productForm,productNewCategory:ev.target.value})} label="Category Name" variant="outlined" sx={{
                            flex: 1,
                            display: 'flex'
                        }} />

                            <Button variant="contained" startIcon={<Create/>}
                            sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.primary.dark
                        }}
                            onClick={() => setShowCatCreate(false)}>
                            Create
                            </Button>
                            </CardContent>)}

                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>

                            <TextField id="outlined-basic" label="Quantity" 
 name="productQuantity" onChange={ev=>setProductForm({...productForm,productQuantity:ev.target.value})}
      variant="outlined" sx={{flex:1,display:'flex'}} />

                            {selectedOrigin !== "RawMaterial" &&

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo2"
                                options={optionsQuant}
                                sx={{flex: 1, display: 'flex'}}
                                name="productUnits"
                                onChange={(event, value) => setProductForm({
                                    ...productForm,
                                    productUnits: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                    label="Units"
                                />}
                            />

                            }

                        </CardContent>
                        {selectedOrigin !== "RawMaterial" &&
                        <CardContent>
                            <TextField id="outlined-basic" label="Size"

                                       name="productSize"
                                       onChange={ev => setProductForm({...productForm, productSize: ev.target.value})}

                                       variant="outlined" sx={{flex: 1, display: 'flex'}}/>
                        </CardContent>

                        }
                        {selectedOrigin !== "RawMaterial" &&

                        <CardContent>
                            <TextField id="outlined-basic" label="Product Details" 
 name="productDetails" onChange={ev=>setProductForm({...productForm,productDetails:ev.target.value})}

      variant="outlined" sx={{flex:1 ,display:'flex'}}
                                       multiline
                            />
                        </CardContent>

                        }

                        {selectedOrigin === "RawMaterial" &&
                        <CardContent sx={{display: 'flex', flexDirection: 'colum', justifyContent: 'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo2"
                                options={optionsQuant}
                                sx={{flex: 1, display: 'flex'}}
                                name="productUnits"
                                onChange={(event, value) => setProductForm({
                                    ...productForm,
                                    productUnits: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                                                    label="Units"
                                />}
                            />
                        </CardContent>

                        }

                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" startIcon={<Create />} color="secondary" onClick={(ev)=> addProductFormCreate(ev)} >
                                Create
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setmodal(false)}>
                                Cancel
                            </Button>

                        </CardContent>


                    </ModalBody>

                </Card>

            </Modal>


            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                <Header title="Warehouse" />
                <FlexBetween
                    backgroundColor ={theme.palette.background.alt}
                    borderRadius = "9px"
                    gap="3rem"
                    p="0.1rem 1.5rem"
                    sx={{marginX:5,flexGrow:1}}
                >
                    <InputBase  
                        value={search} onChange={ev => setSearch(ev.target.value)}
                        placeholder="Search..."/>
                    <IconButton>
                        <Search onClick={ev=>setSearch(search)}/>
                    </IconButton>
                </FlexBetween>

            </CardContent>
            <Button variant="contained" startIcon={<Add />}
            onClick={()=>setmodal(true)}>
                New Product
            </Button>

            <Button variant="outlined" startIcon={<LocalShippingRounded />}
                    sx={{borderColor:theme.palette.secondary.light,
                        marginX:2,
                        color:theme.palette.secondary.light,
                        }}
                    onClick={()=>setmodalDis(
                        true)}>
                Dispatch
            </Button>

            <Modal
                size='lg'
                isOpen={modaldis}
                toggle={()=>setmodalDis(!modaldis)}

            >
                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Dispatch Product
                        </Typography>
                    </ModalHeader>

                    <ModalBody>

                        {/* <CardContent>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsDisProduct}
                                sx={{flex:1,display:'flex'}}
                                name="dispatchProduct"
                                onChange={(ev, value) => setDispatchProductForm({
                                    ...dispatchProductForm,
                                    dispatchProduct: value
                                })}
                                renderInput={(params) => <TextField {...params} label="Product to be Dispatched"
                                />}
                            />
                        </CardContent> */}
                             <CardContent>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={optionsDisProduct}
                getOptionLabel={(option) => option.label} // Specify which property to use for the display label
                sx={{ flex: 1, display: 'flex' }}
                name="dispatchProduct"
                onChange={(event, value) => setDispatchProductForm({
                    ...dispatchProductForm,
                    dispatchProduct: value ? value.value : null
                })}
                renderInput={(params) => (
                    <TextField {...params} label="Product to be Dispatched" />
                )}
            />
        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <TextField id="outlined-basic" name="dispatchQuantity" onChange={ev=>setDispatchProductForm({...dispatchProductForm,dispatchQuantity:ev.target.value})} label="Quantity" variant="outlined" sx={{flex:1,display:'flex'}} />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsQuant}
                                sx={{flex:1,display:'flex'}}
                                name="dispatchUnit"
                                onChange={(event, value) => setDispatchProductForm({
                                    ...dispatchProductForm,
                                    dispatchUnit: value
                                })}
                                renderInput={(params) => <TextField {...params} label="Units"
                                />}
                            />
                        </CardContent>

                        <CardContent>
                            <TextField id="outlined-basic" name="dispatchTo" onChange={ev=>setDispatchProductForm({...dispatchProductForm,dispatchTo:ev.target.value})} label="Dispatch Product to..." variant="outlined" sx={{flex:1 ,display:'flex'}}
                                multiline
                            />
                        </CardContent>

                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" endIcon={<Send/>} onClick={()=>addDispatchProductFormCreate()} color="secondary" >
                                Send
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setmodalDis(false)}>
                                Cancel
                            </Button>

                        </CardContent>

                    </ModalBody>

                </Card>

            </Modal>


            <Modal
                size='lg'
                isOpen={modaledit}
                toggle={()=>setmodaledit(!modaledit)}

            >
                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Edit Product
                        </Typography>
                    </ModalHeader>

                    <ModalBody>
                        <CardContent>
                            <TextField id="outlined-basic" label="Product Name" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" label="Product Description" variant="outlined" sx={{flex:1,display:'flex'}}
                                       multiline/>
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsCat}
                                sx={{flex:1,display:'flex'}}

                                renderInput={(params) => <TextField {...params}
                                                                    name="productCategory" value={productForm.productCategory} onChange={ev=>setProductForm({productCategory:ev.target.value})}
                                                                    label="Category"
                                />}
                            />
                            <Button variant="contained" startIcon={<CategoryRounded />}
                                   sx={{
                                    ':hover': {
                                 bgcolor: 'white',
                                 color: 'black',
                                    },
                                   backgroundColor: "black",
                                   color: "white",
                                   fontSize: "14px",
                                   fontWeight: "bold",
                                   padding: "10px 20px",
                               }}
                                    onClick={() => setShowCatCreate(true)} >
                                Create New Category
                            </Button>
                        </CardContent>
                        {showCatCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" label="Category Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                        sx={{backgroundColor:theme.palette.secondary.light,
                                            color:theme.palette.primary.dark}}
                                        onClick={() => setShowCatCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>)}
                        <CardContent>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Product Origin</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Produced"
                                    name="productOrigin" value={productForm.productOrigin} onChange={ev=>setProductForm({productOrigin:ev.target.value})}
                                >
                                    <FormControlLabel value="Produced" control={<Radio />} label="Produced" />
                                    <FormControlLabel value="Purchased" control={<Radio />} label="Purchased" />
                                    <FormControlLabel value="RawMaterial" control={<Radio />} label="Raw Material" />

                                </RadioGroup>
                            </FormControl>
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <TextField id="outlined-basic" label="Quantity"
                                       name="productQuantity" onChange={ev=>setProductForm({productQuantity:ev.target.value})}
                                       variant="outlined" sx={{flex:1,display:'flex'}} />

                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsQuant}
                                sx={{flex:1,display:'flex'}}
                                renderInput={(params) => <TextField {...params}
                                                                    name="productUnits" value={productForm.productUnits} onChange={ev=>setProductForm({productUnits:ev.target.value})}
                                                                    label="Units"
                                />}
                            />
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" label="Size" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" startIcon={<Save />} color="secondary" >
                                Save
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setmodaledit(false)}>
                                Cancel
                            </Button>

                        </CardContent>
                    </ModalBody>
                </Card>
            </Modal>

            <Box
                mt="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={(data && data.data) || []}
                    columns={columns}
                    rowCount={(data && data.data) || 0}
                    rowsPerPageOptions={[20, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) => setSort(...newSortModel)}
                />
            </Box>
              <Modal
                size='lg'
                isOpen={alert.show}
              >
                <Alert
                  severity={alert.type}
                  btnText={'Close'}
                  onClose={onCloseAlert}
                  pressCloseOnOutsideClick={true}
                  showBorderBottom={true}
                >
                  <AlertTitle>{alert.title}</AlertTitle>
                {alert.text}
                </Alert>
              </Modal>

        </Box>
    );
};

export default Warehouse;
