import {Navigate, Outlet} from "react-router-dom";
import {useStateContext} from "../../state/ContextProvider";

export default function BlankLayout() {
	const {token} = useStateContext()

	if(token){
		return <Navigate to= "/dashboard"/>
	}

  	return(
		<div>
		  <Outlet/>
		</div>
	)
}
