import React, {useEffect, useState} from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Button,
    Typography,
    Rating,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { ThemeProvider, createTheme } from '@mui/material';
import LogBox from "../../components/LogBox";
import DateP from "../../components/DateP";
import Requestbox from "../../components/Requestbox";
import axiosClient from "../../state/axios-client";
import JobCardPurchase from "../../components/JobCardPurchase";





function Requests() {
    const theme = useTheme();

    useEffect(() => {
        //getDepartments();
        getUserRequests();
    }, [])

    const [departments, setDepartments] = useState()
    const getDepartments = () => {
        axiosClient.get('/departments')
            .then(({ data }) => {
                setDepartments(data);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [totalRequests, setTotalRequests] = useState()
    const [userRequests, setUserRequests] = useState()
    const getUserRequests = () => {
        axiosClient.get('user-requests')
            .then(({ data }) => {
                setDepartments(data.data.departments);
                setTotalRequests(data.data.requests);
                setUserRequests(data.data.users);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Box>
            <Upper
                totalRequests={totalRequests}
            />
            {userRequests && userRequests.map(user => {
                return (
                    <Requestbox
                        key={user.id}
                        departments={departments}
                        userObj={user}
                    />
                )
            })}
        </Box>
    );
}

const Upper =({totalRequests})=>{

    const theme = useTheme();

    return(

        <CardContent sx={{display:'flex', flexDirection:'colum'}}>
            <Header title="New Users requests" subtitle="The users mentioned below are yet to be authenticated "  />
            <Box sx={{display:'flex', flexDirection:'colum',justifyContent:'flex-end',flex:1}}>
                <Box justifyContent={"center"}>
                    <Typography variant="h1">
                        {totalRequests}
                    </Typography>
                    <Typography>
                        Requests
                    </Typography>
                </Box>
            </Box>

        </CardContent>

    )
}




export default Requests;
