import axios from "axios";

// const axiosClient =	axios.create({
// //   baseURL: `https://server.asvpharmatech.com/api`,
//   baseURL: `http://127.0.0.1:8000/api`,
//   headers:{
//     'X-Request-With':'XMLHttpRequest',
//   },
//   withCredentials: true
// })

// axiosClient.interceptors.request.use((config) => {
// 	const token = localStorage.getItem('ACCESS_TOKEN');
// 	config.headers.Authorization = `Bearer ${token}`;
// 	return config;
// })

// axiosClient.interceptors.response.use((response)=>{
// 	return response;
// }, (error) => {
// 	try{

// 	const {response} = error;
// 	if(response.status === 401){
// 		localStorage.removeItem('ACCESS_TOKEN');		
// 	} 
// 	}catch(e){
// 		console.log(e);	
// 	}
// 	throw error;
// });

// export default axiosClient;

const axiosClient = axios.create({
	baseURL: `https://server.asvpharmatech.com/api`,
	// baseURL: `http://127.0.0.1:8000/api`,
	headers: {
	  'X-Requested-With': 'XMLHttpRequest', // Corrected header name
	},
	withCredentials: true
  });
  
  axiosClient.interceptors.request.use((config) => {
	const token = localStorage.getItem('ACCESS_TOKEN');
	if (token) {
	  config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
  }, (error) => {
	return Promise.reject(error);
  });
  
  axiosClient.interceptors.response.use((response) => {
	return response;
  }, (error) => {
	try {
	  const { response } = error;
	  if (response && response.status === 401) {
		localStorage.removeItem('ACCESS_TOKEN');
		// Redirect user to login page or show appropriate message
	  }
	} catch (e) {
	  console.log(e);
	}
	return Promise.reject(error);
  });
  
  export default axiosClient;
