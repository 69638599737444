import React, {useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    FormControl, FormControlLabel,
    FormLabel, IconButton, Radio, TextField,

    Typography, useTheme
} from "@mui/material";

import {CategoryRounded, Check, CheckRounded, ClearRounded, Create, MoreHoriz} from "@mui/icons-material";
import axiosClient from "../state/axios-client";

const Requestbox = ({departments, userObj})=>{
    const theme = useTheme();
    //const optionsDep = [departments];
    const [userDepartmentOptions, setUserDepartmentOptions] = useState(departments)
    const [user, setUser] = useState(userObj)

    async function addUserDepartment (ev) {
        ev.preventDefault();
        if(user.department_id) {
            try {
                axiosClient.post('user/department/add', user)
                    .then((response) => {
                        //onShowAlert('success',response.data.message)
                    });
            } catch (error) {
                console.log(error)
            }
        }

    }

    return(
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection:'colum',
                marginX:2,
                marginBottom:1,
                justifyContent:'space-between'

            }}
        >

            <CardContent>
                <Typography
                    sx={{ fontSize: 12 }}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                >
                    Name
                </Typography>
                <Typography variant="h5" component="div">
                    {user.name}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography
                    sx={{ fontSize: 12 }}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                >
                    Email
                </Typography>
                <Typography variant="h5" component="div">
                    {user.email}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography
                    sx={{ fontSize: 12 }}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                >
                    Phone Number
                </Typography>
                <Typography variant="h5" component="div">
                    {user.phone_no}
                </Typography>
            </CardContent>
            <CardContent >
                <Typography  color={theme.palette.secondary[400]}>
                    Assign Department
                </Typography>
                <div style={{display:'flex',flexDirection:'colum'}}>
                    <div style={{display:'flex',flexDirection:'colum', marginTop:5}}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={userDepartmentOptions}
                            sx={{flex:1,display:'flex'}}
                            name="department_id"
                            value={user.department_id}
                            onChange={(event, value) => setUser({
                                ...user,
                                department_id: value.value
                            })}
                            renderInput={(params) => <TextField {...params}
                                label="Assign to"
                            />}
                        />
                    </div>
                </div>

            </CardContent>
            <CardContent>
                <div style={{display:'flex',flexDirection:'colum'}}>
                    <IconButton onClick={(ev)=> addUserDepartment(ev)} color={theme.palette.secondary[400]} aria-label="add to shopping cart" >
                        <CheckRounded/>
                    </IconButton>
                    <IconButton color={theme.palette.secondary[400]} aria-label="add to shopping cart" >
                    <ClearRounded/>
                </IconButton>
                </div>
            </CardContent>
        </Card>
    )
}
export default Requestbox;
