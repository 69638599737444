import React, { useState } from "react";
import { Box, useTheme, CardContent, IconButton, InputBase } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import {Search} from "@mui/icons-material";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import FlexBetween from "../../components/FlexBetween";

const Transactions = () => {
    const theme = useTheme();

    // values to be sent to the backend
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");

    const [searchInput, setSearchInput] = useState("");
    const { data, isLoading } = useGetTransactionsQuery({
        page,
        pageSize,
        sort: JSON.stringify(sort),
        search,
    });

    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 1,
        },
        {
            field: "productName",
            headerName: "Product Name",
            flex: 1,
        },
        {
            field: "type",
            headerName: "Transaction Type",
            flex: 1,
        },
        {
            field: "date",
            headerName: "Date",
            flex: 0.5,

        },
        {
            field: "units",
            headerName: "Units",
            flex: 1,
            /*renderCell: (params) => `$${Number(params.value).toFixed(2)}`,*/
        },
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
            <Header title="TRANSACTIONS" subtitle="Entire list of transactions" />

                <FlexBetween
                    backgroundColor ={theme.palette.background.alt}
                    borderRadius = "9px"
                    gap="3rem"
                    p="0.1rem 1.5rem"
                    sx={{marginX:5,flexGrow:1}}
                >
                    <InputBase  
                        value={search} onChange={ev => setSearch(ev.target.value)}
                        placeholder="Search..."/>
                    <IconButton>
                        <Search onClick={ev=>setSearch(search)}/>
                    </IconButton>
                </FlexBetween>
            </CardContent>
            <Box
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={(data && data.data.transactions) || []}
                    columns={columns}
                    rowCount={(data && data.total) || 0}
                />
            </Box>
        </Box>
    );
};

export default Transactions;
