import React, {useState, useEffect} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel, InputAdornment, OutlinedInput, Step, StepLabel, Stepper,
    TextField,
    ThemeProvider,
    Typography,
    useTheme
} from "@mui/material";
import {
    ClearRounded,
    Create,
    DeliveryDining,
    Done,
    FireTruck,
    LocalShippingRounded,
    PeopleAlt,
    Save
} from "@mui/icons-material";
import {FormGroup} from "reactstrap";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo";
import {DesktopDatePicker, TimePicker} from "@mui/x-date-pickers";
import axiosClient from "../state/axios-client";

const JobCardBox = ({jobProductionId, stepObj, productionProduct, mixingObj, extrusionObj, inspectionObj, postCurringObj, packingObj, dispatchObj, currentUserRole}) => {
    const theme = useTheme();

    const [steps, setSteps] = useState(stepObj)
    const [jobStepDone,setJobStepDone] = React.useState({
        jobProductionEntryId: jobProductionId
    });

    const [mixing, setMixing] = useState(mixingObj);
    const [mixingForm,setMixingForm] = React.useState(mixingObj);

    const [extrusion, setExtrusion] = useState(extrusionObj);
    const [extrusionsForm,setExtrusionsForm] = React.useState(extrusionObj);

    const [inspection, setInspection] = useState(inspectionObj);
    const [inspectionForm, setInspectionForm] = React.useState(inspectionObj);

    const [postCurring, setPostCurring] = useState(postCurringObj);
    const [postCurringForm,setPostCurringForm] = React.useState(postCurringObj);

    const [cleanPack, setCleanPack] = useState(packingObj);
    const [packingForm,setPackingForm] = React.useState(packingObj);

    const [dispatch, setDispatch] = useState(dispatchObj);
    const [dispatchForm,setDispatchForm] = React.useState(dispatchObj);
    /*
    const steps = [
        'Job Entry',
        'Mixing',
        'Extrusion / Moulding',
        'Inspection',
        'Post Curring',
        'Cleaning / packing',
        'Dispatch'
    ];*/


    const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
    })

    function onCloseAlert() {
        setAlert({
            type: '',
            text: '',
            show: false
        })
    }

    function onShowAlert(type,text) {
        setAlert({
            type: type,
            text: text,
            show: true
        })
    }

    async function addJobNextStep (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('production/entry/done', jobStepDone)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addMixing (ev) {
        ev.preventDefault();

        if (!mixingForm.rubberQuantity) {
            onShowAlert('error', 'Rubber Qty is required.');
            return false
        }

        if (!mixingForm.curingAgentQuantity) {
            onShowAlert('error', 'Curing Agent Qty is required.');
            return false
        }

        if (!mixingForm.color) {
            onShowAlert('error', 'Color is required.');
            return false
        }

        try {
            axiosClient.post('production/mixing/add', mixingForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addExtrusion (ev) {
        ev.preventDefault();

        if (!extrusionsForm.extrusionDate) {
            onShowAlert('error', 'Extrusion Date is required.');
            return false
        }

        if (!extrusionsForm.startTime) {
            onShowAlert('error', 'Start Time is required.');
            return false
        }

        if (!extrusionsForm.operatorName) {
            onShowAlert('error', 'Operator Name is required.');
            return false
        }

        if (!extrusionsForm.prepTimeSet) {
            onShowAlert('error', 'Prep time. Set is required.');
            return false
        }

        try {
            axiosClient.post('production/extrusion/add', extrusionsForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addInspection (ev) {
        ev.preventDefault();
        try {
            axiosClient.post('production/inspection/add', inspectionForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addPostCurring (ev) {
        ev.preventDefault();

        if (!postCurringForm.ovenProcessDate) {
            onShowAlert('error', 'Oven Process Date is required.');
            return false
        }

        if (!postCurringForm.startTime) {
            onShowAlert('error', 'Start Time is required.');
            return false
        }

        if (!postCurringForm.timeToReach) {
            onShowAlert('error', 'Time to reach 190°C is required.');
            return false
        }

        if (!postCurringForm.timeToSwitchOff) {
            onShowAlert('error', 'Time to Switch off is required.');
            return false
        }

        if (!postCurringForm.periodicCheck) {
            onShowAlert('error', 'Periodic Check is required.');
            return false
        }

        try {
            axiosClient.post('production/post-curring/add', postCurringForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addPacking (ev) {
        ev.preventDefault();

        if (!packingForm.materialLost) {
            onShowAlert('error', 'Oven Process Date is required.');
            return false
        }

        if (!packingForm.totalProduced) {
            onShowAlert('error', 'Start Time is required.');
            return false
        }

        if (!packingForm.extraProductProduced) {
            onShowAlert('error', 'Time to reach 190°C is required.');
            return false
        }

        try {
            axiosClient.post('production/packing/add', packingForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addDispatch (ev) {
        ev.preventDefault();

        if (!dispatchForm.courierName) {
            onShowAlert('error', 'Oven Process Date is required.');
            return false
        }

        if (!dispatchForm.productTrackNo) {
            onShowAlert('error', 'Start Time is required.');
            return false
        }

        if (!dispatchForm.certificateNo) {
            onShowAlert('error', 'Time to reach 190°C is required.');
            return false
        }

        if (!dispatchForm.docketNo) {
            onShowAlert('error', 'Time to reach 190°C is required.');
            return false
        }

        try {
            axiosClient.post('production/dispatch/add', dispatchForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function jobPrint (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('production/print', dispatchForm)
                .then((response)=>{
                    window.open(response.data.data, "_blank");
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }


    let todayStartOfTheDay;
    return(
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection: 'column',
                marginY:1
            }}>

            <CardContent  sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between'
            }}>

                <div variant="contained">
                    <div style={{display:'flex',flexDirection:'colum'}}>

                        <div variant="contained">

                            <Typography color={theme.palette.secondary[500]} variant="h5">
                                {productionProduct.partyName}
                            </Typography>
                            <Typography color={theme.palette.secondary[100]} variant="h7">
                                {productionProduct.orderNo}
                            </Typography>
                        </div>
                        <div variant="contained">
                            <Card sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: "0.55rem",
                                display:'flex',
                                marginX:2,
                                marginBottom:1,
                                justifySelf:'flex-end'
                            }}>
                                <div style={{padding:5}}>
                                    <Typography color={theme.palette.secondary[300]} variant="h6">
                                        {productionProduct.step}
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>

                {currentUserRole != 'production' && dispatchForm.courierName &&
                <Button variant="contained" startIcon={<PeopleAlt />}
                        sx={{backgroundColor:theme.palette.secondary.light,
                            color:theme.palette.primary.dark}}
                        onClick={(ev) => jobPrint(ev)} >
                    Print
                </Button>
                }

            </CardContent>
            <CardContent sx={{
                backgroundImage: "none",
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection:'row'}}>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={productionProduct.stepId} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </CardContent>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                  }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 12 }}
                        color={theme.palette.secondary[700]}
                    >
                        {productionProduct.name}
                    </Typography>
                    <Typography variant="h6" component="div">
                        {productionProduct.category}
                    </Typography>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                  }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 12 }}
                        color={theme.palette.secondary[700]}
                    >
                        Description
                    </Typography>
                        <Typography variant="h6" component="div">
                            {productionProduct.description}
                        </Typography>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 12 }}
                        color={theme.palette.secondary[700]}
                    >
                        Variations
                    </Typography>
                    {productionProduct.variations.map(vari => {
                        return (
                        <div style={{display:'flex',flexDirection:'colum'}}>
                            <div style={{display:'flex',flexDirection:'colum'}}>
                                <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Size -
                                </Typography>
                                <Typography style={{marginLeft:5}}>
                                    {vari.size}
                                </Typography>
                                </div>
                                <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Qantity -
                                </Typography>
                                <Typography style={{marginLeft:5}}>
                                    {vari.quantity} {vari.unit}
                                </Typography>
                            </div>
                        </div>
                        )
                    })}


                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <div style={{display:'flex',flexDirection:'colum'}}>
                        <Typography
                            sx={{ fontSize: 12 }}
                            color={theme.palette.secondary[700]}>
                            Target Date -
                        </Typography>
                        <Typography style={{marginLeft:5}}>
                            {productionProduct.targetDate}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <div style={{display:'flex',flexDirection:'colum'}}>
                        <div style={{display:'flex',flexDirection:'colum'}}>
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Raw Material -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {productionProduct.product}
                            </Typography>
                        </div>
                        <div style={{display:'flex',flexDirection:'colum'}} >
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Batch no -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {productionProduct.batchNo}
                            </Typography>
                        </div>
                        <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                lot no -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {productionProduct.lotNo}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <div style={{display:'flex',flexDirection:'colum'}}>
                        <Typography
                            sx={{ fontSize: 12 }}
                            color={theme.palette.secondary[700]}>
                            Token no -
                        </Typography>
                        <Typography style={{marginLeft:5}}>
                            {productionProduct.token}
                        </Typography>
                        <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Order Reference -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {productionProduct.orderReference}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                <Button variant="contained" color="secondary"
                        onClick={() => setMixing(true)}>
                    Mixing
                </Button>
            </CardContent>
            {mixing && (
                <div>

                <Card
                    sx={{
                        backgroundImage: "none",
                        backgroundColor: theme.palette.background.default,
                        borderRadius: "0.55rem",
                        display:'flex',
                        flexDirection:'column',
                        marginX:1,
                        marginBottom:1

                    }}>
                    <CardContent sx={{display:'flex', flexDirection:'row', justifyContent:'space-between',flex:1}}>
                        <TextField id="outlined-basic" name="rubberQuantity" onChange={ev=>setMixingForm({...mixingForm,rubberQuantity:ev.target.value})} label="Rubber Qty" value={mixingObj.rubberQuantity} variant="outlined" sx={{flex:1, display:'flex'}} />
                        <TextField id="outlined-basic" name="curingAgentQuantity" onChange={ev=>setMixingForm({...mixingForm,curingAgentQuantity:ev.target.value})} label="Curing Agent Qty" value={mixingObj.curingAgentQuantity} variant="outlined" sx={{flex:1, display:'flex', alignSelf:'flex-end'}} />
                    </CardContent>
                    <CardContent>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={mixingObj.dustParticle} />} name="dustParticle" onChange={ev=>setMixingForm({...mixingForm,dustParticle:ev.target.checked})} label="Dust Particles" />
                            <FormControlLabel control={<Checkbox checked={mixingObj.rubberMilling10Mins} />} name="rubberMilling10Mins" onChange={ev=>setMixingForm({...mixingForm,rubberMilling10Mins:ev.target.checked})} label="Rubber Milling 10 mins" />
                            <FormControlLabel  control={<Checkbox checked={mixingObj.rubberWithCurring10Mins} />} name="rubberWithCurring10Mins" onChange={ev=>setMixingForm({...mixingForm,rubberWithCurring10Mins:ev.target.checked})} label="Rubber with curring agent milling 10 mins" />
                        </FormGroup>
                    </CardContent>
                    <CardContent sx={{display:'flex', flexDirection:'row', justifyContent:'space-between',flex:1}}>
                        <TextField id="outlined-basic" name="color" onChange={ev=>setMixingForm({...mixingForm,color:ev.target.value})} label="Color" value={mixingObj.color} variant="outlined" sx={{flex:1, display:'flex'}} />
                    </CardContent>
                    <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                        <Button variant="contained" startIcon={<Save />} onClick={(ev)=> addMixing(ev)} color={productionProduct.stepId == 1 ? "secondary" : "primary"} disabled={productionProduct.stepId !== 1} >
                            Save
                        </Button>
                        {mixingObj && (
                            <Button variant="contained" startIcon={<Done />} onClick={(ev)=> addJobNextStep(ev)} color={productionProduct.stepId == 2 ? "secondary" : "primary"} disabled={productionProduct.stepId > 1} >
                                Completed
                            </Button>
                        )}
                    </CardContent>

                </Card>

                <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                    <Button variant="contained" color="secondary"
                            onClick={() => setExtrusion(true)}>
                        extrusion / Moulding
                    </Button>
                </CardContent>
                    { extrusion && (
                        <div>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.default,
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'column',
                                marginX:1,
                                marginBottom:1

                            }}>
                        <CardContent sx={{display:'flex', flexDirection:'column', justifyContent:'space-between',flex:1}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <ThemeProvider theme={theme}>
                                            <DemoItem label="Extrusion Date" >
                                                <DesktopDatePicker
                                                    name="extrusionDate" onChange={(date) => setExtrusionsForm({...extrusionsForm, extrusionDate: date.format('MM/DD/YYYY')})}  />
                                            </DemoItem>
                                            <DemoItem label="Start Time">
                                                <TimePicker value={extrusionObj ? extrusionObj.startTime : todayStartOfTheDay} name="startTime" onChange={(time) => setExtrusionsForm({...extrusionsForm, startTime: time.format('HH:mm:ss')})} />
                                            </DemoItem>
                                        </ThemeProvider>
                                    </DemoContainer>
                                </LocalizationProvider>
                        </CardContent>
                            <CardContent>
                                <TextField id="outlined-basic" name="operatorName" onChange={ev=>setExtrusionsForm({...extrusionsForm,operatorName:ev.target.value})} value={extrusionObj.operatorName} label="Operator Name" variant="outlined" sx={{flex:1, display:'flex'}} />
                            </CardContent>
                            <CardContent>
                                <TextField id="outlined-basic" name="prepTimeSet" onChange={ev=>setExtrusionsForm({...extrusionsForm,prepTimeSet:ev.target.value})} value={extrusionObj.prepTimeSet} label="Prep time. Set" variant="outlined" sx={{flex:1, display:'flex'}} />
                            </CardContent>
                            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <Button variant="contained" startIcon={<Save />} onClick={(ev)=> addExtrusion(ev)} color={productionProduct.stepId == 2 ? "secondary" : "primary"} disabled={productionProduct.stepId !== 2} >
                                    Save
                                </Button>
                                <Button variant="contained" startIcon={<Done />} onClick={(ev)=> addJobNextStep(ev)} color={productionProduct.stepId == 3 ? "secondary" : "primary"} disabled={productionProduct.stepId > 2} >
                                    Completed
                                </Button>
                            </CardContent>
                        </Card>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                        <Button variant="contained" color="secondary"
                        onClick={() => setInspection(true)}>
                        Inspection
                        </Button>
                        </CardContent>
                        </div>

                    )}

                    { inspection && (
                        <div>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.default,
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'column',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent sx={{display:'flex',
                                flexDirection:'column',
                                justifyContent:'space-between',
                                flex:1}}>
                                    <FormGroup>
                                        <FormControlLabel required control={<Checkbox checked={inspectionObj.inspectionDone} />} name="inspectionDone" onChange={addInspection} label="Inspection is Done" />
                                    </FormGroup>
                            </CardContent>
                        </Card>
                            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                            <Button variant="contained" color="secondary"
                                    onClick={() => setPostCurring(true)}>
                                Post Curring
                            </Button>
                            </CardContent>
                            { postCurring && (
                                <div>
                                    <Card
                                        sx={{
                                            backgroundImage: "none",
                                            backgroundColor: theme.palette.background.default,
                                            borderRadius: "0.55rem",
                                            display:'flex',
                                            flexDirection:'column',
                                            marginX:1,
                                            marginBottom:1

                                        }}>
                                        <CardContent sx={{display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'space-between',flex:1}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <ThemeProvider theme={theme}>
                                                        <DemoItem label="Oven Process Date" >
                                                            <DesktopDatePicker name="ovenProcessDate" onChange={(date) => setPostCurringForm({...postCurringForm, ovenProcessDate: date.format('MM/DD/YYYY')})} />
                                                        </DemoItem>
                                                        <DemoItem label="Start Time">
                                                            <TimePicker defaultValue={todayStartOfTheDay} name="startTime" onChange={(time) => setPostCurringForm({...postCurringForm, startTime: time.format('HH:mm:ss')})} />
                                                        </DemoItem>
                                                    </ThemeProvider>
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </CardContent>
                                        <CardContent sx={{display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'space-between',flex:1}}>
                                                <TextField
                                                    label="Time to reach 190°C"
                                                    id="outlined-start-adornment"
                                                    name="timeToReach"
                                                    value={postCurringObj.timeToReach}
                                                    onChange={ev=>setPostCurringForm({...postCurringForm,timeToReach:ev.target.value})}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">mins</InputAdornment>,
                                                    }}
                                                />
                                        </CardContent>
                                        <CardContent sx={{display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'space-between',flex:1}}>
                                                <TextField
                                                    label="Time to Switch off"
                                                    id="outlined-start-adornment"
                                                    name="timeToSwitchOff"
                                                    value={postCurringObj.timeToSwitchOff}
                                                    onChange={ev=>setPostCurringForm({...postCurringForm,timeToSwitchOff:ev.target.value})}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">hrs</InputAdornment>,
                                                    }}
                                                />
                                        </CardContent>
                                        <CardContent sx={{display:'flex',
                                            flexDirection:'column',
                                            justifyContent:'space-between',flex:1}}>
                                        <FormGroup>
                                            <FormControlLabel required control={<Checkbox checked={postCurringObj.periodicCheck} />} name="periodicCheck" onChange={ev=>setPostCurringForm({...postCurringForm,periodicCheck:ev.target.checked})} label="Periodic Check" />
                                        </FormGroup>
                                        </CardContent>
                                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                            <Button variant="contained" startIcon={<Save />} onClick={(ev)=> addPostCurring(ev)} color={productionProduct.stepId == 4 ? "secondary" : "primary"} disabled={productionProduct.stepId !== 4} >
                                                Save
                                            </Button>
                                            <Button variant="contained" startIcon={<Done />} onClick={(ev)=> addJobNextStep(ev)} color={productionProduct.stepId == 5 ? "secondary" : "primary"} disabled={productionProduct.stepId > 4} >
                                                Completed
                                            </Button>
                                        </CardContent>
                                    </Card>

                                    <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                                        <Button variant="contained" color="secondary"
                                                onClick={() => setCleanPack(true)}>
                                            Cleaning / Packing
                                        </Button>
                                    </CardContent>
                                    { cleanPack && (
                                        <div>
                                            <Card
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                    borderRadius: "0.55rem",
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    marginX:1,
                                                    marginBottom:1

                                                }}>
                                                <CardContent sx={{display:'flex',
                                                    flexDirection:'column',
                                                    justifyContent:'space-between',flex:1}}>

                                                        <TextField
                                                            label="Material Lost"
                                                            id="outlined-start-adornment"
                                                            name="materialLost"
                                                            value={packingObj.materialLost}
                                                            onChange={ev=>setPackingForm({...packingForm,materialLost:ev.target.value})}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                                            }}
                                                        />

                                                </CardContent>
                                                <CardContent sx={{display:'flex',
                                                    flexDirection:'column',
                                                    justifyContent:'space-between',flex:1}}>

                                                    <TextField
                                                        label="Total Produced"
                                                        id="outlined-start-adornment"
                                                        name="totalProduced"
                                                        value={packingObj.totalProduced}
                                                        onChange={ev=>setPackingForm({...packingForm,totalProduced:ev.target.value})}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">units</InputAdornment>,
                                                        }}
                                                    />

                                                </CardContent>
                                                <CardContent sx={{display:'flex',
                                                    flexDirection:'column',
                                                    justifyContent:'space-between',flex:1}}>

                                                        <TextField
                                                            label="Extra Product produced"
                                                            id="outlined-start-adornment"
                                                            name="extraProductProduced"
                                                            value={packingObj.extraProductProduced}
                                                            onChange={ev=>setPackingForm({...packingForm,extraProductProduced:ev.target.value})}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">units</InputAdornment>,
                                                            }}
                                                        />

                                                </CardContent>
                                                <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                                    <Button variant="contained" startIcon={<Save />} onClick={(ev)=> addPacking(ev)} color={productionProduct.stepId == 5 ? "secondary" : "primary"} disabled={productionProduct.stepId !== 5} >
                                                        Save
                                                    </Button>
                                                    <Button variant="contained" startIcon={<Done />} onClick={(ev)=> addJobNextStep(ev)} color={productionProduct.stepId == 6 ? "secondary" : "primary"} disabled={productionProduct.stepId > 5} >
                                                        Completed
                                                    </Button>
                                                </CardContent>
                                            </Card>
                                            {currentUserRole != 'production' &&

                                            <CardContent sx={{
                                                display: 'flex',
                                                flexDirection: 'colum',
                                                justifyContent: 'center'
                                            }}>
                                                <Button variant="contained" color="secondary"
                                                        onClick={() => setDispatch(true)}>
                                                    Dispatch
                                                </Button>
                                            </CardContent>
                                            }
                                        </div>

                                    )}
                                    {currentUserRole != 'production' && dispatch && (
                                        <div>
                                            <Card
                                                sx={{
                                                    backgroundImage: "none",
                                                    backgroundColor: theme.palette.background.default,
                                                    borderRadius: "0.55rem",
                                                    display:'flex',
                                                    flexDirection:'column',
                                                    marginX:1,
                                                    marginBottom:1

                                                }}>
                                                <CardContent>
                                                    <TextField id="outlined-basic"
                                                               name="courierName"
                                                               value={dispatchObj.courierName}
                                                               onChange={ev=>setDispatchForm({...dispatchForm,courierName:ev.target.value})}
                                                               label="Courier Name" variant="outlined" sx={{flex:1, display:'flex'}} />
                                                </CardContent>
                                                <CardContent>
                                                    <TextField id="outlined-basic"
                                                               name="productTrackNo"
                                                               value={dispatchObj.productTrackNo}
                                                               onChange={ev=>setDispatchForm({...dispatchForm,productTrackNo:ev.target.value})}
                                                               label="Product Track no" variant="outlined" sx={{flex:1, display:'flex'}} />
                                                </CardContent>
                                                <CardContent>
                                                    <TextField id="outlined-basic"
                                                               name="certificateNo"
                                                               value={dispatchObj.certificateNo}
                                                               onChange={ev=>setDispatchForm({...dispatchForm,certificateNo:ev.target.value})}
                                                               label="Certificate no" variant="outlined" sx={{flex:1, display:'flex'}} />
                                                </CardContent><CardContent>
                                                    <TextField id="outlined-basic"
                                                               name="docketNo"
                                                               value={dispatchObj.docketNo}
                                                               onChange={ev=>setDispatchForm({...dispatchForm,docketNo:ev.target.value})}
                                                               label="Docket no" variant="outlined" sx={{flex:1, display:'flex'}} />
                                                </CardContent>
                                                <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                                    <Button variant="contained" startIcon={<LocalShippingRounded />} onClick={(ev)=> addDispatch(ev)} color={productionProduct.stepId == 6 ? "secondary" : "primary"} disabled={productionProduct.stepId !== 6} >
                                                        Commit and dispatch
                                                    </Button>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    )}

                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                <Button variant="contained"
                        onClick={() => setMixing(false)}>
                    See Less
                </Button>
            </CardContent>

        </Card>
    )

}
export default JobCardBox;
