import {CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {themeSettings} from "./theme";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "sences/dashboard";
import Layout from "sences/layout";
import Products from "sences/products";
import Customers from "sences/customers";
import Transaction from "sences/transactions";
import Overview from "sences/overview";
import Daily from "sences/daily";
import Monthly from "./sences/monthly";
import Breakdown from "sences/breakdown";
import Admin from "sences/admin";
import WorkLog from "sences/workLog"
import Tasks from "sences/tasks";
import TaskList from "sences/tasks";
import Warehouse from "./sences/warehouse";
import Login from "./sences/login";
import Departments from "./sences/department";
import Requests from "./sences/requests";
import JobCard from "./sences/jobcard";
import { useStateContext } from "state/ContextProvider";
import BlankLayout from "sences/blanklayout";




function App() {
  const mode = useSelector((state)=> state.global.mode)
  const theme = useMemo(() => createTheme(themeSettings(mode)),[mode])
  const {currentUser,token} = useStateContext()


  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Routes>
            <Route path="/" element={<BlankLayout/>}>
              <Route path= "/" element={<Login/>}/>
              <Route path= "/login" element={<Login/>}/>
            </Route>

            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Navigate to="/dashboard"/> }/>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/products" element={<Products/>}/>
              <Route path="/customers" element={<Customers/>}/>
              <Route path="/transactions" element={<Transaction/>}/>
              <Route path="/overview" element={<Overview/>}/>
              <Route path="/daily" element={<Daily/>}/>
              <Route path="/monthly" element={<Monthly/>}/>
              <Route path="/breakdown" element={<Breakdown/>}/>
              <Route path="/admin" element={<Admin/>}/>
              <Route path="/work log" element={<WorkLog/>}/>
              <Route path= "/tasks list" element={<TaskList/>}/>
              <Route path= "/warehouse" element={<Warehouse/>}/>
              <Route path= "/department" element={<Departments/>}/>
              <Route path= "/requests" element={<Requests/>}/>
              <Route path= "/job card" element={<JobCard/>}/>
            </Route>

          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
