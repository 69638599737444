import React, {useState, useEffect} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent, Checkbox, createTheme, FormControl, FormControlLabel, FormLabel,
    IconButton,
    InputBase, InputLabel, OutlinedInput, Radio, RadioGroup,
    TextField, ThemeProvider,
    Typography,
    useTheme,
    Alert,
    AlertTitle, Step, StepLabel,
} from "@mui/material";
import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import {
    Add,
    ClearRounded,
    CategoryRounded,
    Create,
    Search,
    VerifiedUser,
    PeopleAlt,
    Delete,
    HomeOutlined,
    AdminPanelSettingsOutlined,
    AssignmentOutlined,
    WorkHistoryOutlined,
    FactCheck,
    ShoppingCartOutlined,
    Inventory2Outlined, ReceiptLongOutlined
} from "@mui/icons-material";
import FlexBetween from "../../components/FlexBetween";
import {FormGroup, Input, Modal, ModalBody, ModalHeader} from "reactstrap";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo";
import DatePicker from "react-datepicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import {DesktopDatePicker, StaticDatePicker} from "@mui/x-date-pickers";
import JobCardBox from "../../components/JobCardBox";
import JobCardPurchase from "../../components/JobCardPurchase";
import axiosClient from "../../state/axios-client";



const JobCard = () => {
    const theme = useTheme();

    const unitOptions = ['Nos', 'mm','m'];
    const [partyOptions, setPartyOptions] = useState([])
    const [showPartyCreate, setShowPartyCreate] = useState(false);
    const [partyTokenNo, setPartyTokenNo] = useState('');
    const [partyToken, setPartyToken] = useState(false);

    const [categoryOptions, setCategoryOptions] = useState([])
    const [showCategoryCreate, setShowCategoryCreate] = useState(false);

    const [vendorOptions, setVendorOptions] = useState([])
    const [vendorTokenNo, setVendorTokenNo] = useState([])

    const [optionsRawMaterial, setOptionsRawMaterial] = useState([])

    const [jobPurchase, setJobPurchase] = useState(true)
    const [purchaseEntryToken, setPurchaseEntryToken] = useState(false)
    const [purchaseEntryModal, setPurchaseEntryModal] = useState(false)
    const [purchaseProducts, setPurchaseProducts] = useState([])
    const [purchaseProductStep, setPurchaseProductSteps] = useState([])

    const [jobProduction, setJobProduction] = useState(false)
    const [productionEntryToken, setProductionEntryToken] = useState(false)
    const [productionEntryModal, setProductionEntryModal] = useState(false)
    const [productionProducts, setProductionProducts] = useState([])
    const [productionProductStep, setProductionProductStep] = useState([])

    const [currentUserRole, setCurrentUserRole] = useState('');
    useEffect(() => {
        getUserPermission();
    },[])
    const getUserPermission = () => {
        axiosClient.get("/user/permission")
            .then(({data}) => {
                //setUserPermission(data)

                if (data.production) {
                    setCurrentUserRole('production')
                }
                if (data.admin) {
                    setCurrentUserRole('admin')
                }
                if (data.superadmin) {
                    setCurrentUserRole('superadmin')
                }
            })
            .catch(err=>{
                const response = err.response;
                if(response && response.status=== 422) {
                    //console.log(response.data.errors);
                    if(response.data.errors){
                        // setErrors(response.data.errors);
                        //console.log(response.data.message)
                    } else {
                        // setErrors({
                        // 	email:[response.data.message]
                        // });
                        //console.log(response.data.message)
                    }
                }
            })
    }

    useEffect(() => {
        if (currentUserRole === 'superadmin' || currentUserRole === 'admin') {
            loadJobPurchases();
        } else {
            loadJobProductions();
        }
    }, [currentUserRole]);


    const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
    })

    function onCloseAlert() {
        setAlert({
            type: '',
            text: '',
            show: false
        })
    }

    function onShowAlert(type,text) {
        setAlert({
            type: type,
            text: text,
            show: true
        })
    }

    async function loadAllOptions (ev) {
        ev.preventDefault();

        try {
            axiosClient.get('options/get')
                .then((response) => {
                    if (response.data.data.parties) {
                        setPartyOptions(response.data.data.parties);
                    }
                    if (response.data.data.categories) {
                        setCategoryOptions(response.data.data.categories);
                    }
                    if(response.data.data.vendors) {
                        setVendorOptions(response.data.data.vendors);
                    }
                    if(response.data.data.purchaseToken) {
                        setPurchaseEntryToken(response.data.data.purchaseToken);
                    }
                    if(response.data.data.productionToken) {
                        setProductionEntryToken(response.data.data.productionToken);
                    }
                    if(response.data.data.partyToken) {
                        setPartyTokenNo(response.data.data.partyToken);
                        setPartyToken(response.data.data.partyToken);
                    }
                    if(response.data.data.vendorToken) {
                        setVendorTokenNo(response.data.data.vendorToken);
                    }
                });
        } catch (error) {
            onShowAlert('error', error.message)
        }
    }

    async function loadPartyOptions (ev) {
        ev.preventDefault();

        if(partyOptions.length === 0) {
            try {
                axiosClient.get('party/get')
                    .then((response) => {
                        if (response.data.data) {
                            setPartyOptions(response.data.data);
                        }
                    });
            } catch (error) {
                onShowAlert('error', error.message)
            }
        }
    }

    async function loadCategoryOptions (ev) {
        ev.preventDefault();

        if(categoryOptions.length === 0) {
            try {
                axiosClient.get('product/category/get')
                    .then((response) => {
                        if (response.data.data) {
                            setCategoryOptions(response.data.data);
                        }
                    });
            } catch (error) {
                onShowAlert('error', error.message)
            }
        }
    }

    const [purchaseEntryForm,setPurchaseEntryForm] = React.useState({
        partyName:'',
        partyNewName:'',
        partyCode:'',
        productName:'',
        productCategory:'',
        productNewCategory:'',
        productOrderReference:'',
        productDescription:'',
        productTargetDate:'',
        productBatchNo:'',
        productLotNo:'',
        productTokenNo:'',
        variants: [{ productSize: '', productQuantity: '', productUnits: '' }],
    });

    const handlePurchaseVariantChange = (index, fieldName, value) => {
        const updatedVariants = [...purchaseEntryForm.variants];
        updatedVariants[index][fieldName] = value;

        setPurchaseEntryForm({
            ...purchaseEntryForm,
            variants: updatedVariants,
        });
    };

    const deletePurchaseVariant = (index) => {
        const updatedVariants = [...purchaseEntryForm.variants];
        updatedVariants.splice(index, 1);

        setPurchaseEntryForm({
            ...purchaseEntryForm,
            variants: updatedVariants,
        });
    };

    const addPurchaseVariant = () => {
        setPurchaseEntryForm({
            ...purchaseEntryForm,
            variants: [...purchaseEntryForm.variants, { productSize: '', productQuantity: '', productUnits: '' }],
        });
    };

    async function loadJobPurchases () {
        //ev.preventDefault();

        setJobProduction(false)
        setJobPurchase(true)

        if(purchaseProducts.length === 0) {
            try {
                axiosClient.get('purchase/entry/get')
                    .then((response)=>{
                        if(response.data.data) {
                            setPurchaseProductSteps(response.data.data.steps);
                            setPurchaseProducts(response.data.data.products);
                            setVendorTokenNo(response.data.data.vendorToken);
                        }
                    });
            } catch (error) {
                onShowAlert('error',error.message)
            }
        }
    }

    async function loadJobPurchaseModal (ev) {
        ev.preventDefault();

        //loadPartyOptions (ev)
        //loadCategoryOptions (ev)
        loadAllOptions(ev)

        setPurchaseEntryModal(true)

        /*
        try {
            axiosClient.get('vendor/get')
                .then((response)=>{
                    if(response.data.data) {
                        setVendorOptions(response.data.data);
                    }
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }*/
    }

    async function addPurchaseEntry (ev) {
        ev.preventDefault();

        if (!purchaseEntryForm.partyNewName && !purchaseEntryForm.partyName) {
            onShowAlert('error', 'Invalid party.');
            return false
        }

        if (!purchaseEntryForm.productName) {
            onShowAlert('error', 'Product name is required.');
            return false
        }

        if (!purchaseEntryForm.productNewCategory && !purchaseEntryForm.productCategory) {
            onShowAlert('error', 'Invalid product category.');
            return false
        }

        if (!purchaseEntryForm.productBatchNo) {
            onShowAlert('error', 'Invalid batch no.');
            return false
        }

        if (!purchaseEntryForm.productLotNo) {
            onShowAlert('error', 'Invalid lot no.');
            return false
        }

        try {
            axiosClient.post('purchase/entry/add', purchaseEntryForm)
                .then((response)=>{
                    setPurchaseProducts([])
                    loadJobPurchases ()
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    const [productionForm,setProductionEntryForm] = React.useState({
        partyName:'',
        partyNewName:'',
        partyCode:'',
        productName:'',
        productCategory:'',
        productNewCategory:'',
        productOrderReference:'',
        productDescription:'',
        productTargetDate:'',
        productRawMaterial:'',
        productBatchNo:'',
        productLotNo:'',
        productTokenNo:'',
        variants: [{ productSize: '', productQuantity: '', productUnits: '' }],
    });

    const handleProductionVariantChange = (index, fieldName, value) => {
        const updatedVariants = [...productionForm.variants];
        updatedVariants[index][fieldName] = value;

        setProductionEntryForm({
            ...productionForm,
            variants: updatedVariants,
        });
    };

    const deleteProductionVariant = (index) => {
        const updatedVariants = [...productionForm.variants];
        updatedVariants.splice(index, 1);

        setProductionEntryForm({
            ...productionForm,
            variants: updatedVariants,
        });
    };

    const addProductionVariant = () => {
        setProductionEntryForm({
            ...productionForm,
            variants: [...productionForm.variants, { productSize: '', productQuantity: '', productUnits: '' }],
        });
    };

    async function loadJobProductions () {
       //ev.preventDefault();

        setJobPurchase(false)
        setJobProduction(true)
        if(productionProducts.length === 0) {
            try {
                axiosClient.get('production/entry/get')
                    .then((response) => {
                        if (response.data.data) {
                            setProductionProductStep(response.data.data.steps);
                            setProductionProducts(response.data.data.products);
                        }
                    });
            } catch (error) {
                onShowAlert('error', error.message)
            }
        }
    }

    async function loadRawMaterialProductsOptions (ev) {
        ev.preventDefault();

        try {
            axiosClient.get('warehouse/get/raw-material')
                .then((response)=>{
                    if(response.data.data) {
                        setOptionsRawMaterial(response.data.data);
                    }
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }
    }

    async function loadJobProductionModal (ev) {
        ev.preventDefault();

        //loadPartyOptions (ev)
        //loadCategoryOptions (ev)
        loadAllOptions(ev)
        loadRawMaterialProductsOptions (ev)

        setProductionEntryModal(true)
    }

    async function addProductionEntry (ev) {
        ev.preventDefault();

        if (!productionForm.partyNewName && !productionForm.partyName) {
            onShowAlert('error', 'Invalid party.');
            return false
        }

        if (!productionForm.productName) {
            onShowAlert('error', 'Product name is required.');
            return false
        }

        if (!productionForm.productNewCategory && !productionForm.productCategory) {
            onShowAlert('error', 'Invalid product category.');
            return false
        }

        if (!productionForm.productRawMaterial) {
            onShowAlert('error', 'Invalid raw material.');
            return false
        }

        if (!productionForm.productBatchNo) {
            onShowAlert('error', 'Invalid batch no.');
            return false
        }

        if (!productionForm.productLotNo) {
            onShowAlert('error', 'Invalid lot no.');
            return false
        }

        try {
            axiosClient.post('production/entry/add', productionForm)
                .then((response)=>{
                    setProductionProducts([])
                    loadJobProductions (ev)
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    return (
        <Box m="1.5rem 2.5rem">
            <Modal
                size='lg'
                isOpen={productionEntryModal}
                toggle={()=>setProductionEntryModal(!productionEntryModal)}

            >

                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Job Entry Production
                        </Typography>
                    </ModalHeader>

                    <ModalBody>


                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={partyOptions}
                                sx={{flex:1,display:'flex'}}
                                name="partyName"
                                onChange={(event, value) => {setProductionEntryForm({
                                    ...productionForm,
                                    partyName: value,
                                });
                                if (value) {
                                    setPartyTokenNo(value.token);
                                } else {
                                    setPartyTokenNo(partyToken);
                                }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    label="Party Name"
                                />}
                            />
                            <Button variant="contained" startIcon={<PeopleAlt />}
                                    sx={{backgroundColor:theme.palette.secondary.light,
                                        color:theme.palette.primary.dark}}
                                    onClick={() => setShowPartyCreate(true)} >
                                Create New Party
                            </Button>
                        </CardContent>
                        {showPartyCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="partyNewName" onChange={ev=>setProductionEntryForm({...productionForm,partyNewName:ev.target.value})} label="Party Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                        sx={{backgroundColor:theme.palette.secondary.light,
                                            color:theme.palette.primary.dark}}
                                        onClick={() => setShowPartyCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>)}
                        <CardContent>
                            <FormControl disabled >
                                <InputLabel htmlFor="component-outlined">Party Code</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={partyTokenNo}
                                    label="Party Code"
                                />
                            </FormControl>
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="productName" onChange={ev=>setProductionEntryForm({...productionForm,productName:ev.target.value})} label="Product Name" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={categoryOptions}
                                sx={{flex:1,display:'flex'}}
                                name="productCategory"
                                value={productionForm.productCategory}
                                onChange={(event, value) => setProductionEntryForm({
                                    ...productionForm,
                                    productCategory: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                    label="Category"
                                />}
                            />
                            <Button variant="contained" startIcon={<CategoryRounded />}
                                    sx={{
                                        ':hover': {
                                     bgcolor: 'white',
                                     color: 'black',
                                        },
                                       backgroundColor: "black",
                                       color: "white",
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       padding: "10px 20px",
                                   }}
                                    onClick={() => setShowCategoryCreate(true)} >
                                Create New Category
                            </Button>
                        </CardContent>
                        {showCategoryCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="productNewCategory" onChange={ev=>setProductionEntryForm({...productionForm,productNewCategory:ev.target.value})} label="Category Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                        sx={{backgroundColor:theme.palette.secondary.light,
                                            color:theme.palette.primary.dark}}
                                        onClick={() => setShowCategoryCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>)}
                        <CardContent>
                            <TextField id="outlined-basic" name="productOrderReference" onChange={ev=>setProductionEntryForm({...productionForm,productOrderReference:ev.target.value})} label="Order Reference" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <TextField
                                id="outlined-textarea"
                                label="Description"
                                name="productDescription" onChange={ev=>setProductionEntryForm({...productionForm,productDescription:ev.target.value})}
                                placeholder="Write the Work Description...."
                                multiline
                                sx={{flex:1,display:'flex'}}
                            />
                        </CardContent>


                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[400],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'column',
                                marginX:1,
                                marginBottom:1

                            }}>
                            {productionForm.variants.map((variant, index) => (
                                <div key={index}>
                                    {/* Your variant fields */}
                                    <div>
                                        <CardContent>
                                            <TextField id="outlined-basic" name="productSize"  value={variant.productSize}
                                                       onChange={(e) => handleProductionVariantChange(index, 'productSize', e.target.value)} label="Size" variant="outlined" sx={{flex:1,display:'flex'}} />
                                        </CardContent>
                                        <CardContent sx={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                            <TextField id="outlined-basic" label="Quantity"
                                                       name="productQuantity"

                                                       value={variant.productQuantity}
                                                       onChange={(e) => handleProductionVariantChange(index, 'productQuantity', e.target.value)}
                                                       variant="outlined" sx={{flex:1,display:'flex'}} />

                                            <Autocomplete
                                                disablePortal
                                                id={`combo-box-demo-${index}`}
                                                options={unitOptions}
                                                sx={{ flex: 1, display: 'flex' }}
                                                name={`productUnits-${index}`}
                                                value={variant.productUnits}
                                                onChange={(e, value) => handleProductionVariantChange(index, 'productUnits', value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Units"
                                                    />
                                                )}
                                            />}
                                        </CardContent>
                                    </div>

                                    {/* Other fields... */}
                                    <IconButton aria-label="delete" size="large" onClick={() => deleteProductionVariant(index)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            ))}

                        </Card>
                        <Button variant="contained" startIcon={<Add />} onClick={addProductionVariant}>
                            Add Variants
                        </Button>



                        <CardContent>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <ThemeProvider theme={theme}>
                                        <DemoItem label="Target Date">
                                            <DesktopDatePicker name="productTargetDate" onChange={(date) => setProductionEntryForm({...productionForm, productTargetDate: date.format('MM/DD/YYYY')})} />
                                        </DemoItem>
                                    </ThemeProvider>
                                </DemoContainer>
                            </LocalizationProvider>
                        </CardContent>
                        <CardContent>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsRawMaterial}
                                sx={{flex:1,display:'flex'}}
                                name="productRawMaterial"
                                value={productionForm.productRawMaterial}
                                onChange={(event, value) => setProductionEntryForm({
                                    ...productionForm,
                                    productRawMaterial: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                    label="Raw Material"
                                />}
                            />
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="productBatchNo" onChange={ev=>setProductionEntryForm({...productionForm,productBatchNo:ev.target.value})} label="Batch no" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="productLotNo" onChange={ev=>setProductionEntryForm({...productionForm,productLotNo:ev.target.value})} label="Lot no" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <FormControl disabled >
                                <InputLabel htmlFor="component-outlined">Token no</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={productionEntryToken}
                                    label="Token no"
                                    sx={{flex:1,display:'flex'}}

                                />
                            </FormControl>
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" startIcon={<Create />} color="secondary" onClick={(ev)=> addProductionEntry(ev)}>
                                Create
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setProductionEntryModal(false)}>
                                Cancel
                            </Button>
                        </CardContent>
                    </ModalBody>

                </Card>

            </Modal>
            <Modal
                size='lg'
                isOpen={purchaseEntryModal}
                toggle={()=>setPurchaseEntryModal(!purchaseEntryModal)}

            >

                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Job Entry Purchase
                        </Typography>
                    </ModalHeader>

                    <ModalBody>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={partyOptions}
                                sx={{flex:1,display:'flex'}}
                                name="partyName"
                                value={purchaseEntryForm.partyName}
                                onChange={(event, value) => {setPurchaseEntryForm({
                                    ...purchaseEntryForm,
                                    partyName: value,
                                });
                                    if (value) {
                                        setPartyTokenNo(value.token);
                                    } else {
                                        setPartyTokenNo(partyToken);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    label="Party Name"
                                />}
                            />
                            <Button variant="contained" startIcon={<PeopleAlt />}
                                    sx={{backgroundColor:theme.palette.secondary.light,
                                        color:theme.palette.primary.dark}}
                                    onClick={() => setShowPartyCreate(true)} >
                                Create New Party
                            </Button>
                        </CardContent>
                        {showPartyCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="partyNewName" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,partyNewName:ev.target.value})} label="Party Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                        sx={{backgroundColor:theme.palette.secondary.light,
                                            color:theme.palette.primary.dark}}
                                        onClick={() => setShowPartyCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>)}
                        <CardContent>
                            <FormControl disabled >
                                <InputLabel htmlFor="component-outlined">Party Code</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={partyTokenNo}
                                    label="Party Code"
                                />
                            </FormControl>
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="productName" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,productName:ev.target.value})} label="Product Name" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={categoryOptions}
                                sx={{flex:1,display:'flex'}}
                                name="productCategory"
                                value={purchaseEntryForm.productCategory}
                                onChange={(event, value) => setPurchaseEntryForm({
                                    ...purchaseEntryForm,
                                    productCategory: value
                                })}
                                renderInput={(params) => <TextField {...params}
                                    label="Category"
                                />}
                            />
                            <Button variant="contained" startIcon={<CategoryRounded />}
                                     sx={{
                                        ':hover': {
                                     bgcolor: 'white',
                                     color: 'black',
                                        },
                                       backgroundColor: "black",
                                       color: "white",
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       padding: "10px 20px",
                                   }}
                                    onClick={() => setShowCategoryCreate(true)} >
                                Create New Category
                            </Button>
                        </CardContent>
                        {showCategoryCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="productNewCategory" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,productNewCategory:ev.target.value})} label="Category Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                        sx={{backgroundColor:theme.palette.secondary.light,
                                            color:theme.palette.primary.dark}}
                                        onClick={() => setShowCategoryCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>)}
                        <CardContent>
                            <TextField id="outlined-basic" name="productOrderReference" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,productOrderReference:ev.target.value})} label="Order Reference" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <TextField
                                id="outlined-textarea"
                                label="Description"
                                name="productDescription" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,productDescription:ev.target.value})}
                                placeholder="Write the Work Description...."
                                multiline
                                sx={{flex:1,display:'flex'}}
                            />
                        </CardContent>


                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[400],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'column',
                                marginX:1,
                                marginBottom:1

                            }}>
                            {purchaseEntryForm.variants.map((variant, index) => (
                                <div key={index}>
                                    {/* Your variant fields */}
                                    <div>
                                        <CardContent>
                                            <TextField id="outlined-basic" name="productSize"  value={variant.productSize}
                                                       onChange={(e) => handlePurchaseVariantChange(index, 'productSize', e.target.value)} label="Size" variant="outlined" sx={{flex:1,display:'flex'}} />
                                        </CardContent>
                                        <CardContent sx={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                            <TextField id="outlined-basic" label="Quantity"
                                                       name="productQuantity"
                                                       value={variant.productQuantity}
                                                       onChange={(e) => handlePurchaseVariantChange(index, 'productQuantity', e.target.value)}
                                                       variant="outlined" sx={{flex:1,display:'flex'}} />

                                            <Autocomplete
                                                disablePortal
                                                id={`combo-box-demo-${index}`}
                                                options={unitOptions}
                                                sx={{ flex: 1, display: 'flex' }}
                                                name={`productUnits-${index}`}
                                                value={variant.productUnits}
                                                onChange={(e, value) => handlePurchaseVariantChange(index, 'productUnits', value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Units"
                                                    />
                                                )}
                                            />}
                                        </CardContent>
                                    </div>

                                    {/* Other fields... */}
                                    <IconButton aria-label="delete" size="large" onClick={() => deletePurchaseVariant(index)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            ))}

                        </Card>
                        <Button variant="contained" startIcon={<Add />} onClick={addPurchaseVariant}>
                            Add Variants
                        </Button>



                        <CardContent>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <ThemeProvider theme={theme}>
                                        <DemoItem label="Target Date" >
                                            <DesktopDatePicker name="productTargetDate" onChange={(date) => setPurchaseEntryForm({...purchaseEntryForm, productTargetDate: date.format('MM/DD/YYYY')})} />
                                        </DemoItem>
                                    </ThemeProvider>
                                </DemoContainer>
                            </LocalizationProvider>
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="productBatchNo" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,productBatchNo:ev.target.value})} label="Batch no" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <TextField id="outlined-basic" name="productLotNo" onChange={ev=>setPurchaseEntryForm({...purchaseEntryForm,productLotNo:ev.target.value})} label="Lot no" variant="outlined" sx={{flex:1,display:'flex'}} />
                        </CardContent>
                        <CardContent>
                            <FormControl disabled >
                                <InputLabel htmlFor="component-outlined">Token no</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={purchaseEntryToken}
                                    label="Token no"
                                    sx={{flex:1,display:'flex'}}
                                />
                            </FormControl>
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" startIcon={<Create />} onClick={(ev)=> addPurchaseEntry(ev)} color="secondary" >
                                Create
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setPurchaseEntryModal(false)}>
                                Cancel
                            </Button>
                        </CardContent>
                    </ModalBody>

                </Card>

            </Modal>

            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                <Header title="Job Card" />
                <FlexBetween
                    backgroundColor ={theme.palette.background.alt}
                    borderRadius = "9px"
                    gap="3rem"
                    p="0.1rem 1.5rem"
                    sx={{marginX:5,flexGrow:1}}
                >
                    <InputBase placeholder="Search..."/>
                    <IconButton>
                        <Search/>
                    </IconButton>
                </FlexBetween>
                <div>
                    {(currentUserRole == 'superadmin' || currentUserRole == 'admin') &&
                        <Button variant="contained" startIcon={<Add />} sx={{marginX: 4}} onClick={(ev)=>loadJobProductionModal(ev)}>
                            Job Card Production
                        </Button>
                    }

                    {(currentUserRole == 'superadmin' || currentUserRole == 'admin') &&
                        <Button variant="contained" startIcon={<Add />} onClick={(ev)=>loadJobPurchaseModal(ev)}>
                            Job Card Purchase
                        </Button>
                    }
                </div>
            </CardContent>
            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                {(currentUserRole == 'superadmin' || currentUserRole == 'admin') &&
                    <Button  sx={{
                        ':hover': {
                     bgcolor: 'white',
                     color: 'black',
                        },
                       backgroundColor: "black",
                       color: "white",
                       fontSize: "14px",
                       fontWeight: "bold",
                       padding: "10px 20px",
                   }} onClick={(ev)=>{loadJobPurchases()}}>
                        Job Purchase
                    </Button>
                }
                <Button variant="contained" sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }} onClick={(ev)=>{loadJobProductions(ev)}}>
                    Job Production
                </Button>
            </CardContent>
            { jobProduction &&(
                <div>
                    <Header title="Production " />

                    {productionProducts.map(job => {
                        return (
                            <JobCardBox
                                key={job.productId}
                                jobProductionId={job.productId}
                                stepObj={productionProductStep}
                                productionProduct={job.product}
                                mixingObj={job.mixing}
                                extrusionObj={job.extrusion}
                                inspectionObj={job.inspection}
                                postCurringObj={job.postCurring}
                                packingObj={job.packing}
                                dispatchObj={job.dispatch}
                                currentUserRole={currentUserRole}
                            />
                        )
                    })}
                </div>

            )}
            { jobPurchase &&(
                <div>
                    <Header title="Purchase" />
                    {purchaseProducts.map(job => {
                        return (
                            <JobCardPurchase
                                key={job.productId}
                                jobPurchaseId={job.productId}
                                vendorOptions={vendorOptions}
                                stepObj={purchaseProductStep}
                                purchaseProductObj={job.product}
                                placeOrderObj={job.placeOrder}
                                vendorTokenNo={vendorTokenNo}
                                receiveOrderObj={job.receiveOrder}
                                dispatchObj={job.dispatch}
                            />
                        )
                    })}
                </div>
            )}


            <Modal
                size='lg'
                isOpen={alert.show}
            >
                <Alert
                    severity={alert.type}
                    btnText={'Close'}
                    onClose={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    showBorderBottom={true}
                >
                    <AlertTitle>{alert.title}</AlertTitle>
                    {alert.text}
                </Alert>
            </Modal>

        </Box>
    );
};

export default JobCard;
