import React, {useState, useEffect} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox, FormControl,
    FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Step, StepLabel, Stepper,
    TextField,
    ThemeProvider,
    Typography,
    useTheme
} from "@mui/material";
import {
    Add,
    ClearRounded,
    Create, Delete,
    DeliveryDining,
    Done,
    FireTruck,
    LocalShippingRounded,
    PeopleAlt,
    Save
} from "@mui/icons-material";
import {FormGroup, ModalBody} from "reactstrap";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo";
import {DesktopDatePicker, TimePicker} from "@mui/x-date-pickers";
import { parseISO } from 'date-fns';
import axiosClient from "../state/axios-client";

const JobCardPurchase = ({jobPurchaseId, vendorOptions, stepObj, purchaseProductObj, placeOrderObj, vendorTokenNo, receiveOrderObj, dispatchObj}) => {

    const theme = useTheme();

    const unitOptions = ['Nos', 'mm','m'];
    const [steps, setSteps] = useState(stepObj)
    const [showVendorCreate, setShowVendorCreate] = useState(false);
    const [jobStepDone,setJobStepDone] = React.useState({
        jobPurchaseEntryId: jobPurchaseId,
    });

    const [purchaseProduct, setPurchaseProduct] = useState(purchaseProductObj);

    const [placeOrder, setPlaceOrder] = useState(placeOrderObj);
    const [placeOrderForm,setPlaceOrderForm] = React.useState(placeOrderObj);
    const [vendorToken, setVendorToken] = useState(vendorTokenNo)
    //const placeOrderPoDate = parseISO(placeOrderForm.poDate);
    //const placeOrderPoDate = new Date(placeOrderForm.poDate);
    //const [placeOrderPoDefaultDate, setPlaceOrderPoDefaultDate] = useState("01/01/2023");


    const [receiveOrder, setReceiveOrder] = useState(receiveOrderObj);
    const [receiveOrderForm, setReceiveOrderForm] = useState(receiveOrderObj);

    const [dispatch, setDispatch] = useState(dispatchObj);
    const [dispatchForm, setDispatchForm] = useState(dispatchObj);

    //const optionsVendorName = ['SunPharma', 'Global City','Hamster'];

    const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
    })

    function onCloseAlert() {
        setAlert({
            type: '',
            text: '',
            show: false
        })
    }

    function onShowAlert(type,text) {
        setAlert({
            type: type,
            text: text,
            show: true
        })
    }

    async function addJobNextStep (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('purchase/entry/done', jobStepDone)
                .then((response)=>{
                    setPurchaseProduct(response.data.data)
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }
    }

    const handlePurchaseOrderVariantChange = (index, fieldName, value) => {
        const updatedVariants = [...placeOrderForm.variants];
        updatedVariants[index][fieldName] = value;

        setPlaceOrderForm({
            ...placeOrderForm,
            variants: updatedVariants,
        });
    };

    const deletePurchaseOrderVariant = (index) => {
        const updatedVariants = [...placeOrderForm.variants];
        updatedVariants.splice(index, 1);

        setPlaceOrderForm({
            ...placeOrderForm,
            variants: updatedVariants,
        });
    };

    const addPurchaseOrderVariant = () => {
        setPlaceOrderForm({
            ...placeOrderForm,
            variants: [...placeOrderForm.variants, { productSize: '', productQuantity: '', productUnits: '' }],
        });
    };

    async function addPlaceOrder (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('purchase/place-order/add', placeOrderForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addReceiveOrder (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('purchase/receive-order/add', receiveOrderForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function addDispatchForm (ev) {
        ev.preventDefault();

        if (!dispatchForm.courierName) {
            onShowAlert('error', 'Oven Process Date is required.');
            return false
        }

        if (!dispatchForm.productTrackNo) {
            onShowAlert('error', 'Start Time is required.');
            return false
        }

        if (!dispatchForm.certificateNo) {
            onShowAlert('error', 'Time to reach 190°C is required.');
            return false
        }

        if (!dispatchForm.docketNo) {
            onShowAlert('error', 'Time to reach 190°C is required.');
            return false
        }

        try {
            axiosClient.post('purchase/dispatch/add', dispatchForm)
                .then((response)=>{
                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    async function jobPrint (ev) {
        ev.preventDefault();

        try {
            axiosClient.post('purchase/print', dispatchForm)
                .then((response)=>{



                    onShowAlert('success',response.data.message)
                });
        } catch (error) {
            onShowAlert('error',error.message)
        }

    }

    let todayStartOfTheDay;
    return(
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection: 'column',
                marginY:1
            }}>

            <CardContent  sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection:'row',
                justifyContent:'space-between'
            }}>

                <div variant="contained">

                    <div style={{display:'flex',flexDirection:'colum'}}>

                        <div variant="contained">
                            <Typography color={theme.palette.secondary[500]} variant="h5">
                                {purchaseProduct.partyName}
                            </Typography>
                            <Typography color={theme.palette.secondary[100]} variant="h7">
                                {purchaseProduct.orderNo}
                            </Typography>
                        </div>

                        <div variant="contained">
                            <Card sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: "0.55rem",
                                display:'flex',
                                marginX:2,
                                marginBottom:1,
                                justifySelf:'flex-end'
                            }}>

                                <div style={{padding:5}}>
                                    <Typography color={theme.palette.secondary[300]} variant="h6">
                                        {purchaseProduct.step}
                                    </Typography>
                                </div>
                            </Card>
                        </div>

                    </div>
                </div>

                {dispatchForm.courierName &&
                <Button variant="contained" startIcon={<PeopleAlt />}
                        sx={{backgroundColor:theme.palette.secondary.light,
                            color:theme.palette.primary.dark}}
                        onClick={(ev) => jobPrint(ev)} >
                    Print
                </Button>
                }

            </CardContent>
            <CardContent sx={{
                backgroundImage: "none",
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection:'row'}}>

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={purchaseProduct.stepId} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label.stepId}>
                                <StepLabel>{label.step}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

            </CardContent>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 12 }}
                        color={theme.palette.secondary[700]}
                    >
                        {purchaseProduct.name}
                    </Typography>
                    <Typography variant="h6" component="div">
                        {purchaseProduct.category}
                    </Typography>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 12 }}
                        color={theme.palette.secondary[700]}
                    >
                        Description
                    </Typography>
                    <Typography variant="h6" component="div">
                        {purchaseProduct.description}
                    </Typography>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 12 }}
                        color={theme.palette.secondary[700]}
                    >
                        Variations
                    </Typography>
                    {purchaseProduct.variants.map(vari => {
                        return (
                            <div key={vari.variationId} style={{display:'flex',flexDirection:'colum'}}>
                                <div style={{display:'flex',flexDirection:'colum'}}>
                                    <Typography
                                        sx={{ fontSize: 12 }}
                                        color={theme.palette.secondary[700]}>
                                        Size -
                                    </Typography>
                                    <Typography style={{marginLeft:5}}>
                                        {vari.productSize}
                                    </Typography>
                                </div>
                                <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                    <Typography
                                        sx={{ fontSize: 12 }}
                                        color={theme.palette.secondary[700]}>
                                        Qantity -
                                    </Typography>
                                    <Typography style={{marginLeft:5}}>
                                        {vari.productQuantity} {vari.productUnits}
                                    </Typography>
                                </div>
                            </div>
                        )
                    })}
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <div style={{display:'flex',flexDirection:'colum'}}>
                        <Typography
                            sx={{ fontSize: 12 }}
                            color={theme.palette.secondary[700]}>
                            Target Date -
                        </Typography>
                        <Typography style={{marginLeft:5}}>
                            {purchaseProduct.targetDate}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <div style={{display:'flex',flexDirection:'colum'}}>
                        <div style={{display:'flex',flexDirection:'colum'}} >
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Batch no -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {purchaseProduct.batchNo}
                            </Typography>
                        </div>
                        <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                lot no -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {purchaseProduct.lotNo}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <Card
                sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.background.default,
                    borderRadius: "0.55rem",
                    display:'flex',
                    flexDirection:'colum',
                    marginX:1,
                    marginBottom:1

                }}>
                <CardContent>
                    <div style={{display:'flex',flexDirection:'colum'}}>
                        <Typography
                            sx={{ fontSize: 12 }}
                            color={theme.palette.secondary[700]}>
                            Token no -
                        </Typography>
                        <Typography style={{marginLeft:5}}>
                            {purchaseProduct.token}
                        </Typography>
                        <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                            <Typography
                                sx={{ fontSize: 12 }}
                                color={theme.palette.secondary[700]}>
                                Order Refrence -
                            </Typography>
                            <Typography style={{marginLeft:5}}>
                                {purchaseProduct.orderReference}
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                <Button sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                        onClick={() => setPlaceOrder(true)}>
                    Place Order
                </Button>
            </CardContent>
            {placeOrder && (
                <div>

                    <Card
                        sx={{
                            backgroundImage: "none",
                            backgroundColor: theme.palette.background.default,
                            borderRadius: "0.55rem",
                            display:'flex',
                            flexDirection:'column',
                            marginX:1,
                            marginBottom:1

                        }}>
                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={vendorOptions}
                                sx={{flex:1,display:'flex'}}
                                name="vendorName"
                                value={placeOrderForm.vendorName}
                                onChange={(event, value) => {setPlaceOrderForm({
                                    ...placeOrderForm,
                                    vendorName: value,
                                });
                                    if (value) {
                                        setVendorToken(value.token);
                                    } else {
                                        setVendorToken(vendorTokenNo);
                                    }
                                }}

                                renderInput={(params) => <TextField {...params}
                                    label="Vendor Name"
                                />}
                            />
                            <Button variant="contained" startIcon={<PeopleAlt />}
                                     sx={{
                                        ':hover': {
                                     bgcolor: 'white',
                                     color: 'black',
                                        },
                                       backgroundColor: "black",
                                       color: "white",
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       padding: "10px 20px",
                                   }}
                                    onClick={() => setShowVendorCreate(true)} >
                                Create New Vendor
                            </Button>
                        </CardContent>
                        {showVendorCreate && (
                            <CardContent
                                sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                <TextField id="outlined-basic" name="vendorNewName" onChange={ev=>setPlaceOrderForm({...placeOrderForm,vendorNewName:ev.target.value})} label="Vendor Name" variant="outlined" sx={{flex:1,display:'flex'}} />

                                <Button variant="contained" startIcon={<Create />}
                                       sx={{
                                        ':hover': {
                                     bgcolor: 'white',
                                     color: 'black',
                                        },
                                       backgroundColor: "black",
                                       color: "white",
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       padding: "10px 20px",
                                   }}
                                        onClick={() => setShowVendorCreate(false)}>
                                    Create
                                </Button>
                            </CardContent>)}
                        <CardContent>
                            <FormControl disabled >
                                <InputLabel htmlFor="component-outlined">Vendor Code</InputLabel>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={vendorToken}
                                    label="Vendor Code"
                                />
                            </FormControl>
                        </CardContent>
                        <CardContent sx={{display:'flex', flexDirection:'row', justifyContent:'space-between',flex:1}}>
                            <TextField id="outlined-basic" name="phoneNo" onChange={ev=>setPlaceOrderForm({...placeOrderForm,phoneNo:ev.target.value})} value={placeOrderForm.phoneNo} value={placeOrderForm.phoneNo} label="Phone no" variant="outlined" sx={{flex:1, display:'flex'}} />
                            <TextField id="outlined-basic" name="purchaseOrderNo" onChange={ev=>setPlaceOrderForm({...placeOrderForm,purchaseOrderNo:ev.target.value})} value={placeOrderForm.purchaseOrderNo} label="Purchase Order no" variant="outlined" sx={{flex:1, display:'flex', alignSelf:'flex-end'}} />
                        </CardContent>


                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[400],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'column',
                                marginX:1,
                                marginBottom:1

                            }}>
                            {placeOrderForm.variants.map((variant, index) => (
                                <div key={index}>
                                    <div>
                                        <CardContent>
                                            <TextField id="outlined-basic" name="productSize"  value={variant.productSize}
                                                       onChange={(e) => handlePurchaseOrderVariantChange(index, 'productSize', e.target.value)} label="Size" variant="outlined" sx={{flex:1,display:'flex'}} />
                                        </CardContent>
                                        <CardContent sx={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                                            <TextField id="outlined-basic" label="Quantity"
                                                       name="productQuantity"
                                                       value={variant.productQuantity}
                                                       onChange={(e) => handlePurchaseOrderVariantChange(index, 'productQuantity', e.target.value)}
                                                       variant="outlined" sx={{flex:1,display:'flex'}} />

                                            <Autocomplete
                                                disablePortal
                                                id={`combo-box-demo-${index}`}
                                                options={unitOptions}
                                                sx={{ flex: 1, display: 'flex' }}
                                                name={`productUnits-${index}`}
                                                value={variant.productUnits}
                                                onChange={(e, value) => handlePurchaseOrderVariantChange(index, 'productUnits', value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Units"
                                                    />
                                                )}
                                            />
                                        </CardContent>
                                    </div>

                                    {/* Other fields... */}
                                    <IconButton aria-label="delete" size="large" onClick={() => deletePurchaseOrderVariant(index)}>
                                        <Delete />
                                    </IconButton>
                                </div>
                            ))}

                        </Card>
                        <Button variant="contained" startIcon={<Add />} onClick={addPurchaseOrderVariant}>
                            Add Variants
                        </Button>

                        <CardContent>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <ThemeProvider theme={theme}>
                                        <DemoItem label="PO Date" >
                                            <DesktopDatePicker name="poDate" onChange={(date) => setPlaceOrderForm({...placeOrderForm, poDate: date})} />
                                        </DemoItem>
                                    </ThemeProvider>
                                </DemoContainer>
                            </LocalizationProvider>
                        </CardContent>


                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }} variant="contained" startIcon={<Save />} onClick={(ev)=> addPlaceOrder(ev)} color={purchaseProduct.stepId == 1 ? "secondary" : "primary"} disabled={purchaseProduct.stepId !== 1} >
                                Save
                            </Button>
                            <Button variant="contained" startIcon={<Done />} onClick={(ev)=> addJobNextStep(ev)} color={purchaseProduct.stepId == 2 ? "secondary" : "primary"} disabled={purchaseProduct.stepId > 1} >
                                Completed
                            </Button>
                        </CardContent>

                    </Card>

                    <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                        <Button   sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}variant="contained" 
                                onClick={() => setReceiveOrder(true)}>
                            Receive Order
                        </Button>
                    </CardContent>
                    { receiveOrder && (
                        <div>
                            <Card
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.default,
                                    borderRadius: "0.55rem",
                                    display:'flex',
                                    flexDirection:'column',
                                    marginX:1,
                                    marginBottom:1

                                }}>
                                <CardContent sx={{display:'flex', flexDirection:'column', justifyContent:'space-between',flex:1}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <ThemeProvider theme={theme}>
                                                <DemoItem label="Date" >
                                                    <DesktopDatePicker name="date" onChange={(date) => setReceiveOrderForm({...receiveOrderForm, date: date.format('MM/DD/YYYY')})} />
                                                </DemoItem>
                                            </ThemeProvider>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </CardContent>
                                <CardContent>
                                    <TextField id="outlined-basic" name="quantityReceived" onChange={ev=>setReceiveOrderForm({...receiveOrderForm,quantityReceived:ev.target.value})} value={receiveOrderForm.quantityReceived} label="Quantity Received" variant="outlined" sx={{flex:1, display:'flex'}} />
                                </CardContent>
                                <CardContent>
                                    <TextField id="outlined-basic" name="rejectedQuantity" onChange={ev=>setReceiveOrderForm({...receiveOrderForm,rejectedQuantity:ev.target.value})} value={receiveOrderForm.rejectedQuantity} label="Rejected quantity" variant="outlined" sx={{flex:1, display:'flex'}} />
                                </CardContent>
                                <CardContent>
                                    <TextField id="outlined-basic" name="acceptedQuantity" onChange={ev=>setReceiveOrderForm({...receiveOrderForm,acceptedQuantity:ev.target.value})} value={receiveOrderForm.acceptedQuantity} label="Accepted quantity" variant="outlined" sx={{flex:1, display:'flex'}} />
                                </CardContent>
                                <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                    <Button variant="contained" startIcon={<Save />} onClick={(ev)=> addReceiveOrder(ev)} color={purchaseProduct.stepId == 2 ? "secondary" : "primary"} disabled={purchaseProduct.stepId !== 2} >
                                        Save
                                    </Button>
                                    <Button variant="contained" startIcon={<Done />} onClick={(ev)=> addJobNextStep(ev)} color={purchaseProduct.stepId == 3 ? "secondary" : "primary"} disabled={purchaseProduct.stepId > 2} >
                                        Completed
                                    </Button>
                                </CardContent>
                            </Card>

                            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                                <Button sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                                        onClick={() => setDispatch(true)}>
                                    Dispatch
                                </Button>
                            </CardContent>
                        </div>
                    )}

                    {dispatch && (
                        <div>
                            <Card
                                sx={{
                                    backgroundImage: "none",
                                    backgroundColor: theme.palette.background.default,
                                    borderRadius: "0.55rem",
                                    display:'flex',
                                    flexDirection:'column',
                                    marginX:1,
                                    marginBottom:1

                                }}>
                                <CardContent>
                                    <TextField id="outlined-basic" label="Courier Name" variant="outlined" sx={{flex:1, display:'flex'}} />
                                </CardContent>
                                <CardContent>
                                    <TextField id="outlined-basic" label="Product Track no" variant="outlined" sx={{flex:1, display:'flex'}} />
                                </CardContent>
                                <CardContent>
                                    <TextField id="outlined-basic" label="Certificate no" variant="outlined" sx={{flex:1, display:'flex'}} />
                                </CardContent><CardContent>
                                <TextField id="outlined-basic" label="Docket no" variant="outlined" sx={{flex:1, display:'flex'}} />
                            </CardContent>
                                <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                                    <Button variant="contained" startIcon={<LocalShippingRounded />} onClick={(ev)=> addDispatchForm(ev)} color={purchaseProduct.stepId == 4 ? "secondary" : "primary"} disabled={purchaseProduct.stepId !== 4} >
                                        Commit and dispatch
                                    </Button>
                                </CardContent>
                            </Card>
                        </div>
                    )}
                </div>
            )}
            <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'center'}}>
                <Button variant="contained"
                        onClick={() => setPlaceOrder(false)}>
                    See Less
                </Button>
            </CardContent>

        </Card>
    )

}
export default JobCardPurchase;
