import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore} from "@reduxjs/toolkit";
import globalReducer from "state";
import {Provider} from "react-redux";
import {setupListeners} from "@reduxjs/toolkit/query";
import {api} from "state/api";
import 'bootstrap/dist/css/bootstrap.css'
import {ContextProvider} from './state/ContextProvider'

const store = configureStore({
    reducer:{
        global: globalReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware:(getDefault) =>getDefault().concat(api.middleware)
});
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <ContextProvider>
        <App />
        </ContextProvider>
      </Provider>
  </React.StrictMode>
);


