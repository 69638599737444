import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    // baseQuery: fetchBaseQuery({ baseUrl: `http://localhost:8000/api/` }),   
    reducerPath: "adminApi",
    tagTypes: [
        "User", "Products","ProductsAdd",
        "Customers","Dashboard",
        "Transactions","Sales","Departments",
        "Admins","WorkLog","TaskList"],
    endpoints: (build) => ({
        getUser: build.query({
            query: (id) => `general/user/${id}`,
            providesTags: ["User"],
        }),
        getProducts:build.query({
            query:()=> "warehouse/product/get",
            providesTags:["Products"],
        }),
        postProducts: build.query({
            query: ({ 
                  productName,
                  productCategory,
                  productQuantity,
                  productUnits,
                  productSize,
                  productDetails,
                  productOrigin,
              }) => ({
                url: "client/products/add",
                method: "POST",
                params: {
                  productName,
                  productCategory,
                  productQuantity,
                  productUnits,
                  productSize,
                  productDetails,
                  productOrigin,
                },
            }),
            providesTags: ["ProductsAdd"],
        }),
        getCustomers:build.query({
            query:()=> "client/customers",
            providesTags:["Customers"],
        }),
        getDepartments:build.query({
            query:()=> "management/departments",
            providesTags:["Department"],
        }),
        getTransactions: build.query({
            query: ({ page, pageSize, sort, search }) => ({
                url: "client/transaction",
                method: "GET",
                params: { page, pageSize, sort, search },
            }),
            providesTags: ["Transactions"],
        }),
        getSales:build.query({
            query:()=> "sales/sales",
            providesTags:["Sales"],
        }),
        getAdmins:build.query({
            query:({page,pageSize,sort,search})=> ({
                url: "management/admins",
                method: "GET",
                params: { page, pageSize, sort, search }
            }),
            providesTags:["Admins"],
        }),
        getDashboard:build.query({
            query:()=> "general/dashboard",
            providesTags:["Dashboard"],
        }),
        getTasks:build.query({
            query:()=> "management/work log",
            providesTags:["WorkLog"],
        }),
        getTasksList:build.query({
            query:()=> "management/task/list",
            providesTags:["TaskList"],
        }),
        getWorkLog:build.query({
            query:()=> "management/work/log",
            providesTags:["TaskList"],
        }),
    }),

});

export const {
    useGetUserQuery,
    useGetProductsQuery,
    usePostProductsQuery,
    useGetCustomersQuery,
    useGetTransactionsQuery,
    useGetSalesQuery,
    useGetAdminsQuery,
    useGetDashboardQuery,
    useGetDepartmentsQuery,
    useGetTasksListQuery,
    useGetWorkLogQuery
} = api;
