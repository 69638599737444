import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton,
  InputAdornment, InputLabel, OutlinedInput,
  TextField,
  Typography,
  useTheme,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle 
} from "@mui/material";
import { useGetAdminsQuery } from "state/api";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import {
  Add,
  ClearRounded,
  Create,
  Edit,
  Diversity3Rounded,
  PersonAddAlt1Rounded,
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import { FormGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import axiosClient from "../../state/axios-client";

const Admin = () => {
  const theme = useTheme();
  const { data, isLoading} = useGetAdminsQuery();
  const [users,setUsers] = useState();
  const [errors,setErrors] = useState();
  const[email,setEmail] = useState()
  const [selectedId,setSelectedId] = useState(null)
  const[open,setOpen] = useState(false)
  const columns = [

    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone_no",
      headerName: "Phone Number",
      flex: 0.5,
      renderCell: (params) => {
        return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      },
    },
    {
      field: "department_name",
      headerName: "Department",
      flex: 0.5,
    },
    {
      field: "edit",
      headerName: "Actions",
      renderCell: (params) => (
         <div style={{display:'flex',justifyContent:'space-around',width:'100%'}}>
         <Button  sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }} onClick={(ev) => editUser(params.id)} >
            Edit
          </Button>
          <Button  sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: '#EE4E4E',
                             },
                            backgroundColor: "#EE4E4E",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }} onClick={(ev) => handleClickDelete(ev,params.id)} >
          Delete
        </Button>

        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button   sx={{
                             ':hover': {
                          bgcolor: 'white',
                          color: 'black',
                             },
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button 
           sx={{
            ':hover': {
         bgcolor: 'white',
         color: '#EE4E4E',
            },
           backgroundColor: "#EE4E4E",
           color: "white",
           fontSize: "14px",
           fontWeight: "bold",
           padding: "10px 20px",
       }}
          onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

        {/* <Button variant="contained" color="secondary" onClick={(ev) => editUser(params.id)} >
        Edit
      </Button> */}
      </div>
      ),
      flex: 0.5,
    },
  ];
  const [modalNuser, setmodalNuser] = useState(false)
  const [editUserModal, setEditUserModal] = useState(false)
  const [modalNdep, setmodalNdep] = useState(false)
  const optionsDep = ['Production', 'Tech', 'Accounts'];
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [state, setState] = React.useState({
    warehouse: false,
    moderator: false,
    dispatch: false,
  });
  const { warehouse, moderator, dispatch } = state;

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [dept, setDept] = useState({
    name: null,
    warehouse: false,
    superadmin: false,
    admin: false,
    production: false
  })

  const [newUser, setNewUser] = useState({
    name: null,
    department_id: null,
    email: null,
    password: null,
    phone_no: null,
    status: 1,
  })


  useEffect(() => {
    getDepartments();
    getUsers();
  }, [])

  const [departments, setDepartments] = useState()

  const getDepartments = () => {
    axiosClient.get('/departments')
      .then(({ data }) => {
        setDepartments(data.data);
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getUsers= () => {
    axiosClient.get('/users')
      .then(({ data }) => {
        setUsers(data.data);
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const createUser = (ev) => {
    ev.preventDefault();
    if(validateEmail(newUser.email)){
      axiosClient.post("/users", newUser)
      .then(({ data }) => {
        console.log("response" + data)
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          console.log(response.data.errors);
          console.log(newUser);
          // setErrors(response.data.errors);
        }
      })
    }
    else{
      alert("Not a valid email")
    }
    
  }
  /*
  const editUser = (id)=>{
    //setNewUser(users[id])
    console.log("users[id]")
    console.log(id)
    //console.log(users[id])
    //setEditUserModal(true)
  }*/
 

  const handleEmailChange = (ev) => {
    const email = ev.target.value;
    setNewUser({ ...newUser, email });

    if (!validateEmail(email)) {
      setErrors({ ...errors, email: 'Invalid email address' });
    } else {
      setErrors({ ...errors, email: '' });
    }
  };

  function editUser(id) {
    console.log(id);
    const user = users.find(user => user.id === id);
    console.log(user);
    setNewUser(user)
    setEditUserModal(true)
  }

  function handleClickDelete(ev,id){
    ev.preventDefault()
    setSelectedId(id)
    setOpen(true)
  }

  const handleClose = ()=>{
    setOpen(false)
    setSelectedId(null)
  }

  const handleConfirmDelete=()=>{
    deleteUser(selectedId)
    handleClose()
  }
  // function delete(id){
  //   console.log(id)
  //   const user = users.
  // }
  const updateUser = (id) => {
    axiosClient.put("/users/"+id, newUser)
      .then(({ data }) => {
        console.log("response" + data)
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          console.log(response.data.errors);
          console.log(newUser);
          // setErrors(response.data.errors);
        }
      })
  }

  const deleteUser =(id)=>{
    axiosClient.delete("/users/"+id).then(({data})=>{
      console.log(data)
    }).catch(err=>{
      const response = err.response;
      if(response && response.status === 422){
        console.log(response.data.errors)
      }
    })
  }
  const createDepartment = (ev) => {
    ev.preventDefault();
    axiosClient.post("departments", dept)
      .then(({ data }) => {
        console.log("response" + data)
      })
      .catch(err => {
        const response = err.response;
        if (response && response.status === 422) {
          console.log(response.data.errors);
          console.log(dept);
          // setErrors(response.data.errors);
        }
      })
  }




  return (
    
    <Box m="1.5rem 2.5rem">
      <Modal
        size='lg'
        isOpen={modalNuser}
        toggle={() => setmodalNuser(!modalNuser)}

      >
        <Card sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.primary[600],
          flexWrap: 'wrap'
        }}>
          <ModalHeader>
            <Typography color={theme.palette.secondary[500]} variant="h4">
              Add New User
            </Typography>
          </ModalHeader>

          <ModalBody>
            <CardContent>
              <TextField id="outlined-basic" value={newUser.name} onChange={ev => setNewUser({ ...newUser, name: ev.target.value })} label="User Name" variant="outlined" sx={{ flex: 1, display: 'flex' }} />
            </CardContent>
            <CardContent>
              {/*

                          <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsDep}
                                sx={{flex:1,display:'flex'}}
                                renderInput={(params) => <TextField {...params} value={newUser.department_id}  label="Assign to"
                                />}
                            />
      */}

              <b><label >Choose Department:</label> &nbsp;</b>
              <select name='department' className="btn-select" onChange={ev => setNewUser({ ...newUser, department_id: parseInt(ev.target.value) })} id="department">

                {newUser.department_id != null ? (
                  <option value={newUser.department_id}>{newUser.department_name} (ID: {newUser.department_id}) (Selected)</option>
                ) :
                  <option>(Select Department)</option>
                }
                {departments && departments.map(department => (
                  <option value={department.id}>{department.name} (ID: {department.id})</option>
                ))}
              </select>


            </CardContent>
            <CardContent>
              <TextField 
              id="outlined-basic" 
              label="Email"
              value={newUser.email} 
              onChange={(ev) => { handleEmailChange(ev)
                 return setNewUser({ ...newUser, email: ev.target.value })}} 
              variant="outlined"
              sx={{ flex: 1, display: 'flex' }}
              required
              inputProps={{
                type: "email",
              }}
              />
            </CardContent>
            <CardContent>
              <TextField id="outlined-basic" label="Phone Number" value={newUser.phone_no} onChange={ev => setNewUser({ ...newUser, phone_no: ev.target.value })} variant="outlined" sx={{ flex: 1, display: 'flex' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }}
              />
            </CardContent>
             <CardContent>
      <FormControl sx={{ flex: 1, display: 'flex' }} variant="outlined">
        <TextField
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="outlined"
          value={newUser.password}
          onChange={(ev) => setNewUser({ ...newUser, password: ev.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </CardContent>

            <CardContent sx={{ display: 'flex', flexDirection: 'colum', justifyContent: 'space-between' }}>
              <Button variant="contained" startIcon={<Create />} onClick={createUser} color="secondary" >
                Create
              </Button>
              <Button variant="contained" startIcon={<ClearRounded />} onClick={() => setmodalNuser(false)}>
                Cancel
              </Button>

            </CardContent>


          </ModalBody>

        </Card>

      </Modal>
      <Modal
        size='lg'
        isOpen={modalNdep}
        toggle={() => setmodalNdep(!modalNdep)}

      >
        <Card sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.primary[600],
          flexWrap: 'wrap'
        }}>
          <ModalHeader>
            <Typography color={theme.palette.secondary[500]} variant="h4">
              New Department
            </Typography>
          </ModalHeader>

          <ModalBody>
            <CardContent>
              <TextField id="outlined-basic" label="Department Name" value={dept.name} onChange={ev => setDept({ ...dept, name: ev.target.value })} variant="outlined" sx={{ flex: 1, display: 'flex' }} />
            </CardContent>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Assign powers</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={dept.warehouse} value={dept.warehouse} onChange={ev => setDept({ ...dept, warehouse: !dept.warehouse })} name="warehouse" />
                  }
                  label="Warehouse"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={dept.admin} value={dept.admin} onChange={ev => setDept({ ...dept, admin: !dept.admin })} name="Admin" />
                  }
                  label="Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={dept.superadmin} value={dept.superadmin} onChange={ev => setDept({ ...dept, superadmin: !dept.superadmin })} name="SuperAdmin" />
                  }
                  label="Super Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={dept.production} value={dept.production} onChange={ev => setDept({ ...dept, production: !dept.production })} name="Production" />
                  }
                  label="Production"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={dept.account} value={dept.account} onChange={ev => setDept({ ...dept, account: !dept.account })} name="Account" />
                    }
                    label="Account"
                />
              </FormGroup>
              <FormHelperText>The following powers will be given to every member of the department</FormHelperText>
            </FormControl>
            <CardContent sx={{ display: 'flex', flexDirection: 'colum', justifyContent: 'space-between' }}>
              <Button variant="contained" startIcon={<Create />} onClick={createDepartment} color="secondary" >
                Create
              </Button>
              <Button variant="contained" startIcon={<ClearRounded />} onClick={() => setmodalNdep(false)}>
                Cancel
              </Button>

            </CardContent>


          </ModalBody>

        </Card>

      </Modal>


      <Modal
        size='lg'
        isOpen={editUserModal}
        toggle={() => setEditUserModal(!editUserModal)}

      >
        <Card sx={{
          backgroundImage: "none",
          backgroundColor: theme.palette.primary[600],
          flexWrap: 'wrap'
        }}>
          <ModalHeader>
            <Typography color={theme.palette.secondary[500]} variant="h4">
              Edit User
            </Typography>
          </ModalHeader>

          <ModalBody>
            <CardContent>
              <TextField id="outlined-basic" value={newUser.name} onChange={ev => setNewUser({ ...newUser, name: ev.target.value })} label="User Name" variant="outlined" sx={{ flex: 1, display: 'flex' }} />
            </CardContent>
            <CardContent>
              {/*

                          <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={optionsDep}
                                sx={{flex:1,display:'flex'}}
                                renderInput={(params) => <TextField {...params} value={newUser.department_id}  label="Assign to"
                                />}
                            />
      */}

              <b><label >Choose Department:</label> &nbsp;</b>
              <select name='department' className="btn-select" onChange={ev => setNewUser({ ...newUser, department_id: parseInt(ev.target.value) })} id="department">

                {newUser.department_id != null ? (
                  <option value={newUser.department_id}>{newUser.department_name} (ID: {newUser.department_id}) (Selected)</option>
                ) :
                  <option>(Select Department)</option>
                }
                {departments && departments.map(department => (
                  <option value={department.id}>{department.name} (ID: {department.id})</option>
                ))}
              </select>


            </CardContent>
            <CardContent>
              <TextField id="outlined-basic" label="Email" value={newUser.email} onChange={ev => setNewUser({ ...newUser, email: ev.target.value })} variant="outlined" sx={{ flex: 1, display: 'flex' }}
              />
            </CardContent>
            <CardContent>
              <TextField id="outlined-basic" label="Phone Number" value={newUser.phone_no} onChange={ev => setNewUser({ ...newUser, phone_no: ev.target.value })} variant="outlined" sx={{ flex: 1, display: 'flex' }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }}
              />
            </CardContent>
            {/* <CardContent>
              <FormControl sx={{ flex: 1, display: 'flex' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <TextField
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  value={newUser.password}
                  onChange={ev => setNewUser({ ...newUser, password: ev.target.value })}
                />
              </FormControl>
            </CardContent> */}
     <CardContent>
      <FormControl sx={{ flex: 1, display: 'flex' }} variant="outlined">
        <TextField
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="outlined"
          value={newUser.password}
          onChange={(ev) => setNewUser({ ...newUser, password: ev.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </CardContent>
            <CardContent sx={{ display: 'flex', flexDirection: 'colum', justifyContent: 'space-between' }}>
              <Button variant="contained" startIcon={<Create />} onClick={updateUser} color="secondary" >
                Create
              </Button>
              <Button variant="contained" startIcon={<ClearRounded />} onClick={() => setEditUserModal(false)}>
                Cancel
              </Button>

            </CardContent>


          </ModalBody>

        </Card>

      </Modal>






      <Box sx={{ display: 'flex', flexDirection: 'colum' }}>
        <Header title="ADMINS" subtitle="Managing admins and list of admins" />
        <Box sx={{ display: 'flex', flexDirection: 'colum', alignSelf: 'flex-end', justifyContent: 'flex-end', flex: 1 }}>
          <Button variant="contained" startIcon={<Add />} sx={{ marginX: 2 }} onClick={() => setmodalNuser(true)}>
            New User
          </Button>
          <Button variant="contained" startIcon={<Add />} onClick={() => setmodalNdep(true)}>
            New Department
          </Button>
        </Box>

      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'colum' }}>
        <Box sx={{ margin: 3, alignContent: 'center', flex: 1 }}>
          <Link to="/department">
            <Button variant="contained" startIcon={<Diversity3Rounded />}>
              Department details
            </Button>
          </Link>
        </Box>
        <Box sx={{ margin: 3, alignContent: 'center', flex: 1 }}>
          <Link to="/requests">
            <Button variant="contained" startIcon={<PersonAddAlt1Rounded />}>
              Users Request
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={!users}
          getRowId={(row) => row.id}
          rows={users || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />
      </Box>


    </Box>
  );
};

export default Admin;
