import React,{useEffect, useState} from 'react';
import {LightModeOutlined,DarkModeOutlined,
Menu as MenuIcon , Search , SettingsOutlined,
ArrowDropDownOutlined} from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import {useDispatch} from "react-redux";
import {setMode} from "state";
import profileImage from "assets/profile.jpg"
import {
    AppBar,
    Button,
    Box,
    Typography,
    IconButton,
    InputBase,
    Toolbar,
    Menu,
    MenuItem,
    useTheme,
} from "@mui/material";
import axiosClient from "../state/axios-client";
import { useStateContext } from "../state/ContextProvider";



const Navbar = ({
    isSidebarOpen,
    setIsSidebarOpen
                }) =>{
    const dispatch = useDispatch();
    const theme= useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const {setToken,user} = useStateContext()
    const [currentUser, setCurrentUser] = useState("");

	useEffect(() => {
		getUser();
	},[])


	const getUser = () => {
		// setErrors(null);
		axiosClient.get("/user")
		.then(({data}) => {
      //console.log(data)
      setCurrentUser(data)
      handleClose()
		})
		.catch(err=>{
			const response = err.response;
			if(response && response.status=== 422) {
				//console.log(response.data.errors);
				if(response.data.errors){
					// setErrors(response.data.errors);
          //console.log(response.data.message)
				} else {
					// setErrors({
					// 	email:[response.data.message]
					// });
          //console.log(response.data.message)
				}
			}
		})
	}




	const onLogout = () => {
		// setErrors(null);
		axiosClient.post("/logout",user)
		.then(({data}) => {
      //console.log(data)
			setToken(null)
      handleClose()
		})
		.catch(err=>{
			const response = err.response;
			if(response && response.status=== 422) {
				//console.log(response.data.errors);
				if(response.data.errors){
					// setErrors(response.data.errors);
          //console.log(response.data.message)
				} else {
					// setErrors({
					// 	email:[response.data.message]
					// });
          //console.log(response.data.message)
				}
			}
		})
	}

  //console.log(user.name)


    return (
        <AppBar
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
            }}
            >
            <Toolbar sx={{justifyContent: "space-between"}}>
                <FlexBetween>
                    <IconButton onClick={()=> setIsSidebarOpen(!isSidebarOpen)}>
                        <MenuIcon/>
                    </IconButton>
                </FlexBetween>

                {/*right*/}
                <FlexBetween gap="1.5rem">
                    {/* <IconButton onClick={()=> dispatch(setMode())}>
                      
                    </IconButton> */}
                    <IconButton>
                        <SettingsOutlined sx={{fontSize: "25px"}} />
                    </IconButton>
                        <FlexBetween>
                            <Button onClick={handleClick} sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textTransform: "none",
                                gap: "1rem",
                            }}>
                                <Box textAlign="left">
                                    <Typography
                                        fontWeight="bold"
                                        fontSize="0.85rem"
                                        sx={{ color: 'black' }}
                                    >
                                        {currentUser.name}
                                    </Typography>
                                    <Typography
                                        fontSize="0.85rem"
                                        sx={{ color: 'black' }}
                                    >
                                        ({currentUser.department})
                                    </Typography>
                                    <Typography
                                        fontSize="0.75rem"
                                        sx={{ color: 'black' }}
                                    >
                                        ({currentUser.phone_no})
                                    </Typography>
                                </Box>
                                <ArrowDropDownOutlined
                                     sx={{ color: 'black' }}
                                />
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={isOpen}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            >
                                <MenuItem onClick={ev=>onLogout(ev)}>Log Out</MenuItem>
                            </Menu>
                        </FlexBetween>


                </FlexBetween>
            </Toolbar>
        </AppBar>
    );
};
export default Navbar;
