import React, {useState, useEffect} from 'react';
import './App.css';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Button,
    Typography,
    Rating,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import { ThemeProvider, createTheme } from '@mui/material';
import LogBox from "../../components/LogBox";
import DateP from "../../components/DateP";
//import {useGetWorkLogQuery} from "../../state/api";
import axiosClient from "../../state/axios-client";


function App() {
    const theme = useTheme();

    const [totalTask, setTotalTask] = useState(0)
    const [completeTask, setCompleteTask] = useState(0)
    const [pendingTask, setPendingTask] = useState(0)
    const [workLog, setWorkLog] = useState([])
    useEffect(() => {
        getWorkLog();
    }, [])
    const getWorkLog = () => {
        axiosClient.get("management/work/log")
            .then(({data}) => {
                setTotalTask(data.data.totalTask)
                setCompleteTask(data.data.completeTask)
                setPendingTask(data.data.pendingTask)
                setWorkLog(data.data.taskList)
            })
            .catch(err=>{
                const response = err.response;
                if(response && response.status=== 422) {
                    //console.log(response.data.errors);
                    if(response.data.errors){
                        // setErrors(response.data.errors);
                        //console.log(response.data.message)
                    } else {
                        // setErrors({
                        // 	email:[response.data.message]
                        // });
                        //console.log(response.data.message)
                    }
                }
            })
    }

    return (
       <Box>
           <Upper
               totalTask={totalTask}
               completeTask={completeTask}
               pendingTask={pendingTask}
           />

           {workLog && workLog.map(task => (
               <LogBox
                   key={task.id}
                   id={task.id}
                   name={task.name}
                   department={task.department}
                   details={task.details}
                   status={task.status}
                   runTime={task.runTime}
                   timestamp={task.timestamp}
                   product={task.product}
               />
           ))}
       </Box>
    );
}

const Upper =({totalTask, completeTask, pendingTask})=>{

    const theme = useTheme();

    const additionalText = " Tasks to be done";
    const mergedString = `${pendingTask}${additionalText}`;

    return(

      <CardContent sx={{display:'flex', flexDirection:'column'}}>
          <Header title="Task Logs" subtitle={mergedString}   />
          <Box sx={{display:'flex', flexDirection:'row',justifyContent:'flex-end',flex:1}}>
              <Box justifyContent={"flex-start"}>
                  <Typography variant="h1">
                      {pendingTask}
                  </Typography>
                  <Typography>
                      In-progress
                  </Typography>
              </Box>
              {/*<Box sx={{backgroundColor:theme.palette.secondary[400] , width:1, margin: 1, flexWrap:'wrap'}}/>*/}
              <Box alignContent={"center"} marginX={1}>
                  <Typography variant="h1">
                      {completeTask}
                  </Typography>
                  <Typography>
                      Completed
                  </Typography>
              </Box>
          </Box>

      </CardContent>

    )
}




export default App;
