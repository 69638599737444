import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Button,
    Typography,
    useTheme,
    IconButton,
    TextField,
    Autocomplete,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import React, {useState} from "react";
import {
    AddShoppingCartOutlined,
    CategoryRounded,
    ClearRounded,
    Create, Done,
    MoreHoriz,
    MoreRounded
} from "@mui/icons-material";
import {DotsItem} from "@nivo/core";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import axiosClient from "../state/axios-client";

const LogBox = ({id,name, department, details, status, runTime, timestamp, product}) => {
    const [productForm,setProductForm] = React.useState({
        productName:product.productName,
        productCategory:product.productCategory,
        productQuantity:product.productQuantity,
        productUnits:product.productUnits,
        productSize:product.productSize,
        productDetails:product.productDetails,
        productOrigin:product.productOrigin
    })
    const theme = useTheme();
    const [taskId, setTaskId] = useState({
        taskId:id
    })
    const [modal, setmodal] = useState(false)
    const optionsCat = ['Pharama tech', 'simulations','equipment'];
    const optionsQuant = ['Nos', 'mm','m'];
    const optionsDep = ['Production', 'Tech','Accounts'];
    const optionsDisProduct = ['Gasket', 'Silicon','Wheels'];
    const [showCatCreate, setShowCatCreate] = useState(false);
    const [selectedValue, setSelectedValue] = useState('general');
    const isProductionOrDispatch = selectedValue === 'production' || selectedValue === 'dispatch';
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
    };

    async function taskComplete () {

        try {
            axiosClient.post('task/complete', taskId)
                .then((response)=>{
                    setmodal(false);
                });
        } catch (error) {

        }

    }

    return(
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
                display:'flex',
                flexDirection:'row',
                marginX:2,
                marginBottom:1,
                justifyContent:'space-between'

            }}
        >
            <Modal
                size='lg'
                isOpen={modal}
                toggle={()=>setmodal(!modal)}

            >
                <Card  sx={{
                    backgroundImage: "none",
                    backgroundColor: theme.palette.primary[600],
                    flexWrap:'wrap'
                }}>
                    <ModalHeader>
                        <Typography color={theme.palette.secondary[500]} variant="h4">
                            Task Details
                        </Typography>
                    </ModalHeader>

                    <ModalBody>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[800],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'colum',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: 12 }}
                                    color={theme.palette.secondary[700]}
                                >
                                    {productForm.productCategory}
                                </Typography>
                                <Typography variant="h6" component="div">
                                    {productForm.productName}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[800],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'colum',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: 12 }}
                                    color={theme.palette.secondary[700]}
                                >
                                    Description
                                </Typography>
                                <Typography variant="h6" component="div">
                                    {productForm.productDetails}
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[800],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'colum',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent>
                                <div style={{display:'flex',flexDirection:'colum'}}>
                                    <div style={{display:'flex',flexDirection:'colum'}}>
                                        <Typography
                                            sx={{ fontSize: 12 }}
                                            color={theme.palette.secondary[700]}>
                                            Size -
                                        </Typography>
                                        <Typography style={{marginLeft:5}}>
                                            {productForm.productSize}
                                        </Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                        <Typography
                                            sx={{ fontSize: 12 }}
                                            color={theme.palette.secondary[700]}>
                                            Quantity -
                                        </Typography>
                                        <Typography style={{marginLeft:5}}>
                                            {productForm.productQuantity} {productForm.productUnits}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[800],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'colum',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent>
                                <div style={{display:'flex',flexDirection:'colum'}}>
                                    <Typography
                                        sx={{ fontSize: 12 }}
                                        color={theme.palette.secondary[700]}>
                                        Target Date -
                                    </Typography>
                                    <Typography style={{marginLeft:5}}>
                                        11 / 12 /  2022
                                    </Typography>
                                </div>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[800],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'colum',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent>
                                <div style={{display:'flex',flexDirection:'colum'}}>
                                    <div style={{display:'flex',flexDirection:'colum'}}>
                                        <Typography
                                            sx={{ fontSize: 12 }}
                                            color={theme.palette.secondary[700]}>
                                            Raw Material -
                                        </Typography>
                                        <Typography style={{marginLeft:5}}>
                                            silicon
                                        </Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                        <Typography
                                            sx={{ fontSize: 12 }}
                                            color={theme.palette.secondary[700]}>
                                            Batch no -
                                        </Typography>
                                        <Typography style={{marginLeft:5}}>
                                            11
                                        </Typography>
                                    </div>
                                    <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                        <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                            <Typography
                                                sx={{ fontSize: 12 }}
                                                color={theme.palette.secondary[700]}>
                                                lot no -
                                            </Typography>
                                            <Typography style={{marginLeft:5}}>
                                                11
                                            </Typography>
                                        </div>

                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card
                            sx={{
                                backgroundImage: "none",
                                backgroundColor: theme.palette.primary[800],
                                borderRadius: "0.55rem",
                                display:'flex',
                                flexDirection:'colum',
                                marginX:1,
                                marginBottom:1

                            }}>
                            <CardContent>
                                <div style={{display:'flex',flexDirection:'colum'}}>
                                    <Typography
                                        sx={{ fontSize: 12 }}
                                        color={theme.palette.secondary[700]}>
                                        Token no -
                                    </Typography>
                                    <Typography style={{marginLeft:5}}>
                                        A 01
                                    </Typography>
                                    <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                                        <Typography
                                            sx={{ fontSize: 12 }}
                                            color={theme.palette.secondary[700]}>
                                            Order Refrence -
                                        </Typography>
                                        <Typography style={{marginLeft:5}}>
                                            AC120-email
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <CardContent sx={{display:'flex', flexDirection:'colum',justifyContent:'space-between'}}>
                            <Button variant="contained" startIcon={<Done />} onClick={(ev) => taskComplete(ev)} color="secondary" >
                                Completed
                            </Button>
                            <Button variant="contained" startIcon={<ClearRounded />} onClick={()=>setmodal(false)}>
                                Cancel
                            </Button>
                        </CardContent>
                    </ModalBody>

                </Card>

            </Modal>
            {/* <CardContent>
                <Typography
                    sx={{ fontSize: 12 }}
                    color={theme.palette.secondary[700]}
                    gutterBottom
                >
                    Department
                </Typography>
                <Typography variant="h5" component="div">
                    {department}
                </Typography>
            </CardContent>
            <CardContent style={{display:'flex',flexDirection:'column',justifySelf:'flex'}} >
                <Typography  color={theme.palette.secondary[400]}>
                    {details}
                </Typography>
                <div style={{display:'flex',flexDirection:'colum'}}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <Typography
                            sx={{ fontSize: 12 }}
                            color={theme.palette.secondary[700]}>
                            Run Time -
                        </Typography>
                        <Typography style={{marginLeft:5}}>
                            {runTime} hrs
                        </Typography>
                    </div>
                    <div style={{display:'flex',flexDirection:'colum',marginLeft:20}} >
                        <Typography
                            sx={{ fontSize: 12 }}
                            color={theme.palette.secondary[700]}>
                            Time Stamp
                        </Typography>
                        <Typography style={{marginLeft:5}}>
                            {timestamp}
                        </Typography>
                    </div>
                </div>
            </CardContent>
            <CardContent sx={{alignContent:'center'}}>
                <Typography color={theme.palette.secondary[400]}>
                    {status}
                </Typography>
            </CardContent>
            <CardContent>
                <IconButton color={theme.palette.secondary[400]} aria-label="add to shopping cart" onClick={()=>setmodal(true)}>
                    <MoreHoriz/>
                </IconButton>
            </CardContent> */}

<div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <CardContent style={{width:'10vw',marginRight:"10px"}}>
        <Typography sx={{ fontSize: 12 }} color={theme.palette.secondary[700]} gutterBottom>
            Department
        </Typography>
        <Typography variant="h5" component="div">
            {department}
        </Typography>
    </CardContent>
    <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',width:'60vw' }}>
        <Typography color={theme.palette.secondary[400]}>
            {details}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 12 }} color={theme.palette.secondary[700]}>
                    Run Time -
                </Typography>
                <Typography style={{ marginLeft: '5px' }}>
                    {runTime} hrs
                </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
                <Typography sx={{ fontSize: 12 }} color={theme.palette.secondary[700]}>
                    Time Stamp
                </Typography>
                <Typography style={{ marginLeft: '5px' }}>
                    {timestamp}
                </Typography>
            </div>
        </div>
    </CardContent>
    <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',gap:'3rem' }}>
        <Typography color={theme.palette.secondary[400]}>
            {status}
        </Typography>
        <IconButton color={theme.palette.secondary[400]} aria-label="add to shopping cart" onClick={() => setmodal(true)}>
            <MoreHoriz />
        </IconButton>
    </CardContent>
</div>

        </Card>
    )
}
export default LogBox;
