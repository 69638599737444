import React from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Collapse,
    Button,
    Typography,
    Rating,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import Header from "components/Header";
import axiosClient from "../../state/axios-client";
import { useEffect } from "react";
import {useState} from "react";

const Department = (
  {
    DepartmentName,
    DepartmentSuperAdmin,
    DepartmentAdmin,
    DepartmentWarehouse,
    DepartmentProduction,
    DepartmentAccount,
    users,
    total_users,
  }
) => {
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Card
            sx={{
                backgroundImage: "none",
                backgroundColor: theme.palette.background.alt,
                borderRadius: "0.55rem",
            }}
        >
            <CardContent>

                <Typography variant="h2" component="div"
                            color={theme.palette.secondary[300]}>
                    {DepartmentName}
                </Typography>

                <CardContent>
                <Typography variant="h5">Powers :-</Typography>
                <Card
                    sx={{
                        backgroundImage: "none",
                        backgroundColor: theme.palette.secondary[100],
                        borderRadius: "0.55rem",
                    }}
                >
                    <CardContent>
      {DepartmentWarehouse ? <Typography variant="body1" color={theme.palette.secondary[700]}>* Warehouse</Typography>:<></>}
      {DepartmentProduction ? <Typography variant="body1" color={theme.palette.secondary[700]}>* Production</Typography>:<></>}
      {DepartmentSuperAdmin ? <Typography variant="body1" color={theme.palette.secondary[700]}>* SuperAdmin</Typography>:<></>}
      {DepartmentAdmin ? <Typography variant="body1" color={theme.palette.secondary[700]}>* Admin</Typography>:<></>}
      {DepartmentAccount ? <Typography variant="body1" color={theme.palette.secondary[700]}>* Account</Typography>:<></>}
                    </CardContent>
                </Card>
                </CardContent>
                <Typography variant="body1">Total Number of Members : {total_users}</Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="primary"
                    size="small"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    See All Members
                </Button>
            </CardActions>
            <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    color: theme.palette.neutral[300],
                }}
            >
                <CardContent>

                    {users && users.map(
                        ({
                             id,
                             name,
                         }) => (
                            <Typography>{name}</Typography>
                        )
                    )}
                </CardContent>
            </Collapse>
        </Card>
    );
};

const Departments = () => {
    const isNonMobile = useMediaQuery("(min-width: 1000px)");
    const [departments, setDepartments] = useState()


    useEffect(()=>{
        try {
          axiosClient.get('department/details')
          .then((response)=>{
            console.log(response.data)
            setDepartments(response.data.data)
            //console.log("departments",departments)
          });
        } catch (error) {
            console.log('error',error.message)
        }
    },[])


    return (
        <Box m="1.5rem 2.5rem">
            <Header title="Departments" />

                <Box
                    mt="20px"
                    display="grid"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    justifyContent="space-between"
                    rowGap="20px"
                    columnGap="1.33%"
                    sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                >

                    {departments && departments.map(
                        ({
                             id,
                             name,
                             admin,
                             superadmin,
                             warehouse,
                             production,
                             account,
                             users,
                             total_users,
                         }) => (
                            <Department key={id}
                                DepartmentName={name}
                                DepartmentSuperAdmin={superadmin}
                                DepartmentAdmin={admin}
                                DepartmentWarehouse={warehouse}
                                DepartmentProduction={production}
                                DepartmentAccount={account}
                                users={users}
                                total_users={total_users}
                            />
                        )
                    )}
                </Box>

        </Box>
    );
};

export default Departments;
