import React from "react";
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import {
    SettingsOutlined,
    ChevronLeft,
    ChevronRightOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    Groups2Outlined,
    ReceiptLongOutlined,
    PublicOutlined,
    PointOfSaleOutlined,
    TodayOutlined,
    CalendarMonthOutlined,
    AdminPanelSettingsOutlined,
    TrendingUpOutlined,
    PieChartOutlined,
    AssignmentOutlined,
    AddTaskOutlined,
    PeopleOutline,
    Inventory2Outlined,
    WorkHistoryOutlined, FactCheck
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/profile.jpg";
import axiosClient from "../state/axios-client";

const Sidebar = ({
                     user,
                     drawerWidth,
                     isSidebarOpen,
                     setIsSidebarOpen,
                     isNonMobile,
                 }) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname]);

    //const [navItems2, setUserPermission] = useState("");

    //const navItems = [];
    const navItems2 = [
        {
            text: "Dashboard",
            icon: <HomeOutlined />,
        },
        {
            text: "Management",
            icon: null,
        },
        {
            text: "Admin",
            icon: <AdminPanelSettingsOutlined />,
        },
        {
            text: "Tasks list",
            icon: <AssignmentOutlined />,
        },
        {
            text: "Work log",
            icon: <WorkHistoryOutlined />,
        },
        {
            text: "Job Card",
            icon: <FactCheck />,
        },
        {
            text: "Commerce",
            icon: null,
        },
        {
            text: "Products",
            icon: <ShoppingCartOutlined />,
        },
        {
            text: "Warehouse",
            icon: <Inventory2Outlined />,
        },
        {
            text: "Transactions",
            icon: <ReceiptLongOutlined />,
        },
        /*
        {
            text: "Sales",
            icon: null,
        },
        {
            text: "Overview",
            icon: <PointOfSaleOutlined />,
        },
        {
            text: "Daily",
            icon: <TodayOutlined />,
        },
        {
            text: "Monthly",
            icon: <CalendarMonthOutlined />,
        },
        {
            text: "Breakdown",
            icon: <PieChartOutlined />,
        },*/


    ];

    const [navItems, setNavItems] = useState([]);
    useEffect(() => {
        getUserPermission();
    },[])
    const getUserPermission = () => {
        axiosClient.get("/user/permission")
            .then(({data}) => {
                //setUserPermission(data)

                let newNavItems = [...navItems];

                if (data.superadmin) {
                    newNavItems.push({
                        text: "Dashboard",
                        icon: <HomeOutlined />,
                    });
                }
                newNavItems.push(
                    {
                        text: "Management",
                        icon: null,
                    },
                );
                if (data.superadmin) {
                    newNavItems.push(
                        {
                            text: "Admin",
                            icon: <AdminPanelSettingsOutlined />,
                        }
                    );
                }

                if (data.superadmin || data.admin) {
                    newNavItems.push(
                        {
                            text: "Tasks list",
                            icon: <AssignmentOutlined />,
                        },
                    );
                }

                newNavItems.push(
                    {
                        text: "Work log",
                        icon: <WorkHistoryOutlined />,
                    },
                );

                if (data.superadmin || data.admin || data.production) {
                    newNavItems.push({
                            text: "Job Card",
                            icon: <FactCheck />,
                        }
                    );
                }

                if (data.superadmin || data.admin || data.warehouse || data.account) {
                    newNavItems.push(
                        {
                            text: "Commerce",
                            icon: null,
                        }
                    );
                }

                if (data.superadmin || data.admin || data.warehouse) {
                    newNavItems.push(
                        {
                            text: "Products",
                            icon: <ShoppingCartOutlined />,
                        },
                        {
                            text: "Warehouse",
                            icon: <Inventory2Outlined />,
                        });
                }

                if (data.superadmin || data.admin || data.account) {
                    newNavItems.push({
                        text: "Transactions",
                        icon: <ReceiptLongOutlined />,
                    });
                }

                setNavItems(newNavItems);

            })
            .catch(err=>{
                const response = err.response;
                if(response && response.status=== 422) {
                    //console.log(response.data.errors);
                    if(response.data.errors){
                        // setErrors(response.data.errors);
                        //console.log(response.data.message)
                    } else {
                        // setErrors({
                        // 	email:[response.data.message]
                        // });
                        //console.log(response.data.message)
                    }
                }
            })
    }

    return (
        <Box component="nav">
            {isSidebarOpen && (
                <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    variant="persistent"
                    anchor="left"
                    sx={{
                        width: drawerWidth,
                        "& .MuiDrawer-paper": {
                            color: theme.palette.secondary[200],
                            backgroundColor: theme.palette.background.alt,
                            boxSixing: "border-box",
                            borderWidth: isNonMobile ? 0 : "2px",
                            width: drawerWidth,
                        },
                    }}
                >
                    <Box width="100%">
                        <Box m="1.5rem 2rem 2rem 3rem">
                            <FlexBetween color={theme.palette.secondary.main}>
                                <Box display="flex" alignItems="center" gap="0.5rem">
                                    <img src="logo512.png" alt="ASV" style={{ width: 'auto', height: '120px' }} />
                                </Box>
                                {!isNonMobile && (
                                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                        <ChevronLeft />
                                    </IconButton>
                                )}
                            </FlexBetween>
                        </Box>
                        <List>
                            {navItems.map(({ text, icon }) => {
                                if (!icon) {
                                    return (
                                        <Typography key={text} sx={{fontWeight:'bold', fontSize:'16px',m: "2.25rem 0 1rem 3rem" }}>
                                            {text}
                                        </Typography>
                                    );
                                }
                                const lcText = text.toLowerCase();

                                return (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/${lcText}`);
                                                setActive(lcText);
                                            }}
                                            sx={{
                                                backgroundColor:
                                                    active === lcText
                                                        ? theme.palette.secondary[300]
                                                        : "transparent",
                                                color:
                                                    active === lcText
                                                        ? theme.palette.primary[600]
                                                        : theme.palette.secondary[100],
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    ml: "2rem",
                                                    color:
                                                        active === lcText
                                                            ? theme.palette.primary[600]
                                                            : theme.palette.secondary[200],
                                                }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                            <ListItemText primary={text} />
                                            {active === lcText && (
                                                <ChevronRightOutlined sx={{ ml: "auto" }} />
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>

                    {/*<Box position="absolute" bottom="2rem">*/}
                    {/*    <Divider />*/}
                    {/*    <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">*/}
                    {/*        <Box*/}
                    {/*            component="img"*/}
                    {/*            alt="profile"*/}
                    {/*            src={profileImage}*/}
                    {/*            height="40px"*/}
                    {/*            width="40px"*/}
                    {/*            borderRadius="50%"*/}
                    {/*            sx={{ objectFit: "cover" }}*/}
                    {/*        />*/}
                    {/*        <Box textAlign="left">*/}
                    {/*            <Typography*/}
                    {/*                fontWeight="bold"*/}
                    {/*                fontSize="0.9rem"*/}
                    {/*                sx={{ color: theme.palette.secondary[100] }}*/}
                    {/*            >*/}
                    {/*                {user.name}*/}
                    {/*            </Typography>*/}
                    {/*            <Typography*/}
                    {/*                fontSize="0.8rem"*/}
                    {/*                sx={{ color: theme.palette.secondary[200] }}*/}
                    {/*            >*/}
                    {/*                {user.occupation}*/}
                    {/*            </Typography>*/}
                    {/*        </Box>*/}
                    {/*        <SettingsOutlined*/}
                    {/*            sx={{*/}
                    {/*                color: theme.palette.secondary[300],*/}
                    {/*                fontSize: "25px ",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </FlexBetween>*/}
                    {/*</Box>*/}
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;
